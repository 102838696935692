import React, { Fragment } from 'react';
import { H3, H2, P, Image, H4 } from '../../../../AbstractElements';
import { IssuedMay, PaymentDue, Email, Mob, TaxIn, Txn, InvoiceHash, OrderHash, BinHash, CustomerId } from '../../../../Constant';
import { Col, Media, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from "../../../../assets/images/rks-logoo.svg";

const InvoiceHeader = (props) => {
    const invoiceData = props.invoice;
    // const company_address = invoiceData.company_address.replace(/"/g, '');
    // const imageUrl = invoiceData.company_logo;
    return (
        <Fragment>
            <div className="invo-header">
                <Row>
                    <Col sm="4" className="custom-col">
                        <Media className='d-flex'>
                            <Media className="flex-grow-1 m-l-20">
                                <img
                                    src={logo}
                                    alt="R.K.S Logo"
                                    className="mb-3"
                                    style={{ width: "150px", height: "auto" }}
                                />
                                {/* <H2 attrH2={{ className: 'media-heading f-w-600' }}>R.K.S.</H2> */}
                                <P>
                                    Unit No 4, 1st Floor,
                                    <br />
                                    Block A, Aman Complex,
                                    <br />
                                    Jalan Aman Kg Sungai Tilong, Jalan
                                    <br />
                                    Muara, Brunei Darussalam - BC3315
                                    <br />
                                    Email: support@rksbrunei.com
                                    <br />
                                    Ph: +673 2338175
                                </P>
                            </Media>
                        </Media>
                    </Col>


                    <Col sm="4" className="custom-col">
                        <div className="text-left">
                            <h5>
                                <span className="digits counter">Bill To: </span>
                            </h5>
                            <P>
                                <strong>{invoiceData?.first_name}</strong> <br />
                                {invoiceData?.billing_address.replace(/"/g, '')}<br />
                                {Mob} : + {invoiceData?.customer_phone_no}<br />
                                {Email} : {invoiceData?.email}<br />
                            </P>
                        </div>
                    </Col>
                    <Col sm="4" className="custom-col">
                        <div className="text-md-end text-xs-center">
                            <span
                                className={`badge badge-${invoiceData?.payment_status} ms-2 ps-2 pe-2 no-print`}
                            >
                                {invoiceData?.payment_status !== "paid" ? "Unpaid" : "Paid"}
                            </span>
                            <H3>
                                <span className="digits counter">#{invoiceData?.invoice_number}</span>
                            </H3>
                            <P>
                                <strong>{Txn} :{invoiceData?.transaction_id} </strong> <br />
                                {invoiceData?.order_id && (
                                    <>
                                        {OrderHash}
                                        <span className="digits">{invoiceData?.order_id}</span>
                                        <br />
                                    </>
                                )}
                                {invoiceData?.stb_no && (
                                    <>
                                        {BinHash}
                                        <span className="digits">{invoiceData?.stb_no}</span>
                                        <br />
                                    </>
                                )}
                                {IssuedMay}
                                <span className="digits">{invoiceData?.invoice_date}</span>
                                <br />
                                {PaymentDue}
                                <span className="digits">{invoiceData?.payment_due_date}</span>
                                <br />
                                {CustomerId} :
                                <span className="digits"> {invoiceData?.subscriber_id}</span>
                            </P>
                        </div>
                    </Col>


                </Row>
            </div>
        </Fragment >
    );
};
export default InvoiceHeader;