import React, { Fragment } from 'react';
import Leftbar from './LeftBar';
import Rightbar from './RightBar';
import Searchbar from './Search';
import { Row } from 'reactstrap';
import CompanyBar from './CompanyBar';
import RksNav from '../../Components/Rks-customer/Heaader/RksNav';
import NavbarsTab from '../../A-RKS/NavBar';

const Header = () => {
    return (
        <Fragment>
            <NavbarsTab />
                {/* <Row className="header-wrapper"> */}
                           
                    {/* <Leftbar /> */}

                    {/* <Searchbar /> */}
                    {/* <Rightbar /> */}
                {/* </Row> */}
        </Fragment >
    );
};
export default Header;