import { useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText, } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/rks-logoo.svg';
import { FaClock, FaFacebook, FaInstagram, FaPhoneAlt } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';

const NavbarsTab = () => {
    const location = useLocation();
    const isLoggedIn = localStorage.getItem("login");

    const Logout = () => {
        localStorage.clear();
    };


    return (
        <div className="header header-layout3">
            <div className="header-topbar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <ul className="contact-list d-flex flex-wrap align-items-center list-unstyled mb-0">
                                    <li>
                                        <IoMdMail size={20} color='#209dd8' />
                                        <div style={{ paddingLeft: '8px' }}>
                                            <span className="color-secondary">Email: </span> <a href="mailto:support@rksbrunei.com" style={{ color: 'white' }}>support@rksbrunei.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <FaClock size={16} color='#209dd8' />
                                        <a href='https://rksbrunei.com/contact-us.php' style={{ paddingLeft: '8px' }}>
                                            <span className="color-secondary">Working Hours: </span> <span>Mon-Sat: 8:00 am - 5:00 pm, Friday closed between 11:00 am - 2:00 pm</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="d-flex align-items-center">
                                    {!isLoggedIn ?
                                        <ul className="header-topbar-links d-flex list-unstyled mb-0 mr-10">
                                            <li><Link to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/sign-up`}>Register</Link></li>
                                            {/* <li><a href="https://rksbrunei.com/">Home</a></li> */}
                                            <li><a href="https://rksbrunei.com/faqs.php">FAQs</a></li>
                                        </ul> :
                                        <ul className="header-topbar-links d-flex list-unstyled mb-0 mr-10">
                                            <li onClick={Logout}>
                                                <Link to={`${process.env.PUBLIC_URL}/login`} onClick={Logout}>Logout</Link>
                                            </li>
                                            {/* <li><a href="https://rksbrunei.com/">Home</a></li> */}
                                            <li><a href="https://rksbrunei.com/faqs.php">FAQs</a></li>
                                        </ul>
                                    }
                                    <ul className="social-icons list-unstyled mb-0">
                                        <li><a href='https://m.facebook.com/61573423114653/'>
                                            <div className="social-icon"><FaFacebook size={22} /></div>
                                        </a></li>
                                        <li><a href='https://www.instagram.com/p/DG2UUOzJWyF/'>
                                            <div className="social-icon"><FaInstagram size={22} /></div>
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg sticky-navbar">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={`${process.env.PUBLIC_URL}/`}>
                        <img src={logo} width="180" height="" alt="logo" />
                    </Link>
                    <div className="collapse navbar-collapse justify-content-center" id="mainNavigation">
                        {isLoggedIn ?
                            <ul className="navbar-nav">
                                <li className="nav-item has-dropdown">
                                    <Link className={`nav-item-link ${location.pathname === "/account" ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/account`}>Account Details</Link>
                                </li>
                                <li className="nav-item has-dropdown">
                                    <Link className={`nav-item-link ${location.pathname === "/profile" ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/profile`}>My Profile</Link>
                                </li>
                                <li className="nav-item has-dropdown">
                                    <Link className={`nav-item-link ${location.pathname === "/activePlan" ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/activePlan`}>My Active Plan</Link>
                                </li>
                                <li className="nav-item has-dropdown">
                                    <Link className={`nav-item-link ${location.pathname === "/invoices" ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/invoices`}>My Invoice</Link>
                                </li>
                                <li className="nav-item has-dropdown">
                                    <Link className={`nav-item-link ${location.pathname === "/transaction" ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/transaction`}>Transactions</Link>
                                </li>
                                <li className="nav-item has-dropdown">
                                    <Link className={`nav-item-link ${location.pathname === "/support" ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/support`}>Support</Link>
                                </li>
                            </ul> :
                            <ul className="navbar-nav">
                                <li class="nav-item has-dropdown">
                                    <a href="https://rksbrunei.com/" class=" nav-item-link">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a href="https://rksbrunei.com/about-us.php" class=" nav-item-link">Company</a>
                                </li>
                                <li class="nav-item ">
                                    <a href="https://rksbrunei.com/services.php" class=" nav-item-link">Services</a>
                                </li>
                                <li class="nav-item ">
                                    <a href="https://rksbrunei.com/pricing.php" class=" nav-item-link">Pricing</a>
                                </li>
                                <li class="nav-item ">
                                    <a href="https://rksbrunei.com/pricing.php" class=" nav-item-link active">My Account</a>
                                </li>
                                <li class="nav-item">
                                    <a href="https://rksbrunei.com/contact-us.php" class="nav-item-link">Contact</a>
                                </li>
                            </ul>
                        }
                        <button className="close-mobile-menu d-block d-lg-none"><i className="fas fa-times"></i></button>
                    </div>
                    <div className="contact-area d-none d-xl-flex align-items-center h-100 ml-30">
                        <a className="contact-phone d-flex align-items-center" href="tel:+673 2338175">
                            <div className="contact-icon"><i className="icon-phone"><FaPhoneAlt /></i></div>
                            <div>
                                <span className="d-block color-body">Need assistance?</span>
                                <span className="phone-number d-block">+673 2338175</span>
                            </div>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavbarsTab