import React, { useEffect, useState } from "react";
import { getClientUrlDataToken, postDataWithToken } from "../../Utils/restUtils";
import { guestComplaintStore, guestfetchBins, guestfetchType } from "../../CommonUrl";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ViewComplainAdd = ({ rowData, setisOpenAdd }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [bins, setBins] = useState([]);
    const [types, setTypes] = useState([]);
    const authState = useSelector((state) => state.auth);

    const boxStyle = {
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        padding: "10px",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
    };

    useEffect(() => {
        fetchBin();
        fetchType();
    }, []);

    const fetchBin = async () => {
        const response = await getClientUrlDataToken(guestfetchBins);
        if (response.status === true) {
            setBins(response.data.map(res => ({ value: res.id, type: res.stb_no })));
        }
    };

    const fetchType = async () => {
        const response = await getClientUrlDataToken(guestfetchType);
        if (response.status === true) {
            setTypes(response.data.map(res => ({ value: res.id, type: res.type })));
        }
    };

    const onSubmit = async (data) => {
        const postData = {
            customer_id: authState?.role,
            problem_type: data?.type,
            stb_no: data?.bin,
            problem_detail: data.comments,
            phone: data.mobile,
            priority: 'high',
        };
        const response = await postDataWithToken(guestComplaintStore, postData);
        if (response?.status === 200) {
            if (response?.data?.status === true) {
                toast.success(response?.data?.message);
                rowData();
                setisOpenAdd(false);
            } else {
                toast.error(response?.data?.message);
            }
        }
    };

    return (
        <div className="container mt-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div style={boxStyle}>
                            <div className="mb-3">
                                <label htmlFor="status" className="form-label blue">Bin Number</label>
                                <select id="status" {...register("bin")} className="form-select">
                                    <option value="" disabled selected>
                                        Select bin
                                    </option>
                                    {bins?.map((e, index) => (
                                        <option key={index} value={e?.type}>
                                            {e?.type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status" className="form-label blue">Request Type</label>
                                <select id="status" {...register("type")} className="form-select">
                                    <option value="" disabled selected>
                                        Select problem type
                                    </option>
                                    {types?.map((e, index) => (
                                        <option key={index} value={e?.value}>
                                            {e?.type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status" className="form-label blue">Mobile Number</label>
                                <input
                                    {...register("mobile")}
                                    type="text"
                                    className="form-control"
                                    placeholder="Mobile Number"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="comment" className="form-label blue">Comment</label>
                                <textarea {...register("comments")} className="form-control" placeholder="Add comment" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col text-center">
                        <button type="submit" className="pay-all">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ViewComplainAdd;
