import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import NavSign from "./NavBar";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useForm } from "react-hook-form";
import { EnterOTP } from "../Constant";
import { H2, P } from "../AbstractElements";
import { postClientUrlWithoutToken } from "../Utils/restUtils";
import {
  guestSendOtp,
  guestSetPassowrd,
  guestVarify,
  guestVarifyOtp,
} from "../CommonUrl";
import NavbarsTab from "./NavBar";


const Signup = () => {
  const [isStep, setnextStep] = useState(1);
  const [OTP, setOTP] = useState("");
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();

  const handleNextStep = () => {
    setnextStep(isStep + 1);
  };

  const handleEmail = () => {};

  const handleMobileEmail = async (data) => {

    try {
      const response = await postClientUrlWithoutToken(guestVarify, {
        email: data?.email,
      });
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          const { token, type } = response?.data?.data;
          setValue("guest_varified_token", token);
          setValue("type", type);
          const sentOtpRes = await postClientUrlWithoutToken(guestSendOtp, {
            identifier: data?.email,
            type: type,
            token: token,
          });
          if (sentOtpRes?.status === 200) {
            if (sentOtpRes?.data?.status === true) {
              toast.success(sentOtpRes?.data?.message);
              handleNextStep();
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOTP = async () => {
    try {
      const response = await postClientUrlWithoutToken(guestVarifyOtp, {
        identifier: getValues("email"),
        type: getValues("type"),
        token: getValues("guest_varified_token"),
        otp: OTP,
      });
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          setValue("otp_varified_token", response?.data?.data?.token);
          handleNextStep();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetPassword = async (data) => {
    try {
      const response = await postClientUrlWithoutToken(guestSetPassowrd, {
        identifier: getValues("email"),
        type: getValues("type"),
        token: getValues("otp_varified_token"),
        otp: OTP,
        password: data?.password,
        password_confirmation: data?.confirm_password,
      });
      if (response?.status === 200) {   
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          navigate("/login");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <NavbarsTab />
      <div className="signup-container d-flex justify-content-center align-item-center">
        <Card className="sign-in-card" style={{marginTop:'30px'}}>
          <CardBody>
            {isStep === 1 && (
              <div className="signin-form-container">
              <h4 className="signin-heading" style={{color: '#534686'}}>Register as a User</h4>
              <p className="signin-subheading" style={{color: '#534686'}}>
                Please enter the email/mobile number
                <br /> registered with RKS
              </p>
                <form
                  className="signin-form"
                  onSubmit={handleSubmit(handleMobileEmail)}
                >
                  <label htmlFor="confirmPassword">Email/Mobile Number</label>
                  <input
                    {...register("email")}
                    type="text"
                    placeholder="Enter Email/Mobile Number"
                  />
                  <div className="text-center mt-3 pb-3">
                    <button type="submit" className="forget-btn">
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            )}

            {isStep === 2 && (
              <FormGroup className="form-group otp-input-component ">
                <Form onSubmit={handleSubmit(handleOTP)}>
                  <Row xs={12}>
                    <Col>
                      <H2 attrH2={{ className: "text-start mt-3 mb-3" }}>
                        {EnterOTP}
                      </H2>
                      <P>
                        Enter the otp send to your Email/phone number
                        {/* <strong>{authState.email}</strong> */}
                      </P>
                      <Col>
                        <div className="otp-input">
                          <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            inputStyles={{
                              borderRadius: "5px",
                              border: "solid 1.5px #74737380",
                              height: "40px",
                              width: "30px",
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "3px",
                              paddingBottom: "10px",
                              marginLeft: "20px",
                            }}
                          />
                          {error && (
                            <span className="text-danger">{error}</span>
                          )}
                          <ResendOTP
                            onResendClick={handleEmail}
                            className="resendOtpClass"
                          />
                        </div>
                      </Col>
                    </Col>
                  </Row>
                  <FormGroup className="">
                    <div className="d-flex  justify-content-between"></div>
                    <div className="otp-button">
                      <button type="submit" className="btn w-100 mt-3">
                        Verify OTP
                      </button>
                    </div>
                  </FormGroup>
                </Form>
              </FormGroup>
            )}
            {isStep === 3 && (
              <div className="">
                <H2 attrH2={{ className: "text-start ms-4 mt-4 mb-3" }}>
                  Set Your Password
                </H2>

                {/* <h4 className="signin-heading text-dark">Set Your Password</h4> */}
                <div className="signin-form-container pt-0">
                  <form
                    className="signin-form"
                    onSubmit={handleSubmit(handleSetPassword)}
                  >
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        {...register("password")}
                        id="password"
                        type="password"
                        placeholder="Enter your password"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="confirmPassword">Re-enter Password</label>
                      <input
                        id="confirmPassword"
                        type="password"
                        {...register("confirm_password")}
                        placeholder="Re-enter password"
                      />
                    </div>
                    <div className="pb-4">
                      <button type="submit" className="forget-btn">
                        Set Password & Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
