import React, { useState, useEffect } from "react";
import {
  Card as BootstrapCard,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
} from "reactstrap";
import {
  List,
  User,
  Calendar,
  FileText,
  RefreshCcw,
  Headphones,
} from "react-feather";
import { FaSearch, FaFilter } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { getClientUrlDataToken, getData } from "../../Utils/restUtils";
import { getDashboard, rksCustomerInvoices } from "../../CommonUrl";
import { useNavigate } from "react-router";
import Popup from "../Common/Popup/popup";
import PayNow from "./PayNow";
import { H3 } from "../../AbstractElements";



const AccountDeatails = () => {
  const [apiResponse, setapiResponse] = useState({
    dashboard: null,
    unpaid_invoices: []
  });
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [modalpay, setmodalPay] = useState(false);



  const cardData = [
    { title: "My Dues", content: 'B$ ' + apiResponse?.dashboard?.overdue_invoice_amount, imageUrl: "/Services/dues.svg" },
    { title: "My Wallet", content: 'B$ ' + apiResponse?.dashboard?.wallet_money, imageUrl: "/Services/wallet.svg" },
    { title: "Unpaid Invoices", content: apiResponse?.dashboard?.unpaid_invoices, imageUrl: "/Services/unpaid.svg" },
    {
      title: "Complaint Registered",
      content: apiResponse?.dashboard?.complaints,
      imageUrl: "/Services/complaint-registered.svg",
    },
  ];

  const invoiceUnpaidColumns = [
    {
      name: <h4>Sl.No</h4>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <h4>Invoice Number</h4>,
      selector: (row) => row.invoice_number,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Invoice Date</h4>,
      selector: (row) => row.invoice_date,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Due/Start Date</h4>,
      selector: (row) => row.due_date,
      sortable: true,
      wrap: true,
    },

    {
      name: <h4>Invoice Status</h4>,
      selector: (row) => row.payment_status,
      sortable: true,
      cell: (row) => (
        <span
          className={`badge ${row.payment_status !== "paid" ? "badge-danger" : "badge-danger"
            }`}
          style={{
            backgroundColor: "#FEF2F2",
            color: row.payment_status !== "paid" ? "#DF2935" : "",
            fontWeight: 400,
            textTransform: "capitalize",
          }}
        >
          {row.payment_status}
        </span>
      ),
      center: true,
    },

    {
      name: <h4>Amount</h4>,
      selector: (row) => 'B$ ' + row.amount,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Action</h4>,
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div className="d-flex">
          <span
            onClick={() => handleViewUnpaid(row?.id)}
            className="btn btn-link"
            style={{ color: "#534686", padding: "0.25rem 0.5rem" }}
          >
            View
          </span>

          {row.payment_status !== "paid" && (
            <button
              className="btn btn-success gradient-green ml-2"
              style={{ padding: "1px 12px" }}
              onClick={() => handlePayment()}
            >
              Pay
            </button>
          )}
        </div>
      ),
      center: true,
    },
    // {
    //   name: <i className="fa fa-gears" style={{ fontSize: "15px" }}></i>,
    //   selector: (row) => row.action,
    //   cell: (row) => <ActionCell row={row} />,
    //   center: true,
    //   width: "90px",
    // },
  ];

  const handleViewUnpaid = (id) => {
    navigate(`/invoice?id=${id}`);
  };


  const handlePayment = (invoiceId) => {
    navigate('/pay', { state: { invoiceId } })
  }
  const handleModalpay = (e) => {
    setmodalPay(!modalpay);
  };


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const ActionCell = ({ row }) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle color="#00000" style={{ fontSize: '14px', zIndex: 1000 }}>
          <i className="fa fa-ellipsis-v"></i>
        </DropdownToggle>
      </Dropdown>
    );
  };

  const fetchAccountData = async () => {
    setLoading(true);
    const response = await getClientUrlDataToken(getDashboard);
    if (response?.status) {

      const dashboardData = response?.data;

      const unpaidResponse = await getClientUrlDataToken(
        rksCustomerInvoices + `?payment_status=unpaid`
      );
      const unpaidTransactions = unpaidResponse?.data?.data || [];

      setapiResponse({
        dashboard: dashboardData,
        unpaid_invoices: unpaidTransactions
      });
    } else {
      setLoading(false);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchAccountData();
  }, [])


  return (
    <div className='container-main' style={{ marginTop: '140px' }}>
      <Row className="height-equals card-box justify-content-center mt-5" style={{ marginLeft: '20px', marginRight: '20px', paddingTop: '30px' }}>
        {cardData.map((data, index) => (
          <Col md="3" key={index} className="mb-4">
            <div className="custom-card">
              <div className="card-title">
                <h5 className="data-title">{data.title}</h5>
                <img
                  src={data.imageUrl}
                  alt={data.title}
                  className="title-icon"
                />
              </div>
              <p className="data-content fw-bold fs-3 pt-4 mb-0  text-start">
                {data.content}
              </p>
            </div>
          </Col>
        ))}
      </Row>
      <Card className="height-equals" style={{ margin: '0px 20px 50px 20px', top: '20px' }}>
        <div>
          <div className="bg-white shadow-sm p-4 rounded-4">
            <h3 className="heading-subtitle" style={{ fontWeight: 'bold', fontSize: '20px', color: '#472e85' }}>Account Details</h3>
            <div className="added-titlerow d-flex flex-column flex-sm-row justify-content-between align-items-center">
              <div>Unpaid invoices</div>
              {/* <div className="icons d-flex gap-3 mt-3 mt-sm-0">
                <FaSearch
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  title="Search"
                />
                <div className="vertical-line"></div>
                <FaFilter
                  style={{ fontSize: "18px", cursor: "pointer" }}
                  title="Filter"
                />
              </div> */}
            </div>
            <div className="support-history table-responsive">
              <DataTable
                columns={invoiceUnpaidColumns}
                data={apiResponse?.unpaid_invoices || []}
                pagination
                paginationServer
                defaultSortAsc
                sortServer
                progressPending={loading}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </div>
          </div>

        </div>
      </Card>
    </div>
  );
};

export default AccountDeatails;
