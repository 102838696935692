import React, { useEffect, useState } from "react";
import { Col, Row, CardBody, Card, Card as BootstrapCard } from "reactstrap";
import { getClientUrlDataToken, postDataWithToken } from "../../Utils/restUtils";
import { guestfetchBills, guestPaymentConfirm, guestPaymentRequest } from "../../CommonUrl";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Transparentspinner from "../Common/Loader/Transparentspinner";
import { useSelector } from "react-redux";

const PayNow = () => {
  const authState = useSelector((state) => state.auth);
  const [apiResponse, setApiResponse] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedSales, setSelectedSales] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBills();
  }, []);

  useEffect(() => {
    if (apiResponse?.total_dues !== undefined) {
      setAmount(apiResponse.total_dues);
    }
  }, [apiResponse]);

  useEffect(() => {
    setAmount(totalAmount.toFixed(2));
  }, [totalAmount]);

  const fetchBills = async () => {
    setLoading(true);
    const response = await getClientUrlDataToken(guestfetchBills);
    if (response?.status) {
      setApiResponse(response);
    }
    setLoading(false);
  };

  const handleCheckboxChange = (id, amount) => {
    setSelectedItems((prevSelectedItems) => {
      const itemExists = prevSelectedItems.some((item) => item.id === id);
      if (itemExists) {
        setTotalAmount((prevTotalAmount) => prevTotalAmount - amount);
        return prevSelectedItems.filter((item) => item.id !== id);
      } else {
        setTotalAmount((prevTotalAmount) => prevTotalAmount + amount);
        return [...prevSelectedItems, { id, amount }];
      }
    });
  };

  const handleSalesOrderDues = (event) => {
    const checked = event.target.checked;
    const salesAmount = parseFloat(apiResponse?.sales_due_total ?? 0);

    setSelectedSales(checked);

    setTotalAmount((prevTotal) =>
      checked ? prevTotal + salesAmount : prevTotal - salesAmount
    );
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const onSubmit = async () => {
    let type = "any";
    const isBinChecked = selectedItems.length > 0;
    if (selectedSales && isBinChecked) {
      type = "bin_with_sales";
    } else if (selectedSales) {
      type = "sales_order";
    } else if (isBinChecked) {
      type = "subscription";
    }

    const postData = {
      request_from: "web",
      customer_id: authState?.role,
      amount: amount,
      bins:
        totalAmount === 0
          ? String(apiResponse?.data?.map((e) => e?.id))
          : String(selectedItems.map((item) => item.id).join(",")),
      gateway: "cybersource",
      type,
    };
    const paymentConfirm = await postDataWithToken(guestPaymentRequest, postData);
    if (paymentConfirm?.status === 200) {
      if (paymentConfirm?.data?.status === true) {
        const response = await getClientUrlDataToken(
          guestPaymentConfirm + paymentConfirm?.data?.data?.order_request_id
        );
        document.open();
        document.write(response);
        document.close();
      } else {
        toast.error(paymentConfirm?.data?.message);
      }
    } else {
      toast.error('Something went wrong');
    }
  };


  return (
    <>
      {loading && <Transparentspinner />}
      <div className="pay-container">
        <Card className="p-1">
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="tab-bar m-3 text-secondary">
                <Link to="/dashboard" className="text-decoration-none text-secondary">
                  Home
                </Link>
                &nbsp;&gt;&nbsp; pay
              </h5>
            </div>
          </div>
        </Card>
        <Card>
          <CardBody className="container">
            <Row>
              <div>
                {apiResponse &&
                  apiResponse?.data?.map((e, index) => (
                    <Col md="12" sm="12" xs="12" className="mb-3 mt-5" key={index}>
                      <div className="paycard-box ">
                        <BootstrapCard className="profile-field-card border">
                          <CardBody className="active-plan">
                            <div className=" d-flex justify-content-between ps-4 pe-4 pt-4 pb-4">
                              <div className="d-flex pt-2 pb-2">
                                <div className="subcription-item-bin ">
                                  <div className="table-column head">Bin Id</div>
                                  <div className="table-column">
                                    {e?.stb_no}
                                  </div>
                                </div>
                                <div className="subcription-item-dues ">
                                  <div className="table-column head">Dues</div>
                                  <div className="table-column due-color">
                                    B$ {e?.total_dues}
                                  </div>
                                </div>
                                <div className="subcription-item-address address">
                                  <div className="table-column head">
                                    Bin Address
                                  </div>
                                  <div className="table-column">
                                    {e?.service_address}
                                  </div>
                                </div>
                              </div>
                              <div className=" ">
                                <input
                                  type="checkbox"
                                  checked={selectedItems.some(
                                    (item) => item.id === e.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(e?.id, e?.total_dues)
                                  }
                                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </BootstrapCard>
                      </div>
                    </Col>
                  ))}

                <div className="pb-5 text-end sale-order-dues">
                  <div className="border rounded-5 p-3 align-items-center">
                    <span className="me-3">Sales Order Dues </span>
                    <span className="me-3 due-color">B$ {(apiResponse?.sales_due_total ?? 0).toFixed(2)}</span>
                    <input type="checkbox" onClick={handleSalesOrderDues}
                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>

        <Card className="rounded-0">
          <CardBody className="container">
            <div className="p-3 sale-order-dues d-flex justify-content-end align-items-center">
              <span className="me-3">Total dues B$</span>
              <div style={{ width: '20%', marginRight: '20px' }}>
                <input
                  type="number"
                  className="form-control"
                  value={amount}
                  onChange={handleAmountChange}
                  style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                />
              </div>
              <button className="btn btn-success" onClick={() => onSubmit()}>
                Pay Now
              </button>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PayNow;
