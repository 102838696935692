import React, { useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import SuccessImage from '../../assets/images/PaymentSuccess.png'
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { postDataWithoutResData } from '../../Utils/restUtils';
import { guestToken } from '../../CommonUrl';

const SuccessPage = () => {
  const location = useLocation();
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("receipt_id");

  useEffect(() => {
    const GetGuestToken = async () => {
      try {
        const returnGuestToken = await postDataWithoutResData(guestToken);
        localStorage.setItem("guestToken", returnGuestToken?.data?.data?.guest_token);
        const expiresIn = returnGuestToken?.data?.data?.expires_in;
        if (expiresIn) {
          setTimeout(() => {
            GetGuestToken();
          }, expiresIn);
        }
      } catch (error) {
        console.error("Error fetching guest token:", error);
      }
    }
    GetGuestToken();
  }, [location])

  return (
    <Card className="payment-success" style={{ marginBottom: '0px', borderRadius: '0px' }}>
      <CardBody>
        <div>
          <div className="d-flex flex-column align-items-center text-center" style={{ justifyContent: "center", marginBottom: '40px' }}>
            <img src={SuccessImage} alt="" />
            <div>
              <h3 className="heading-subtitle" style={{ fontWeight: 'bold', fontSize: '20px', color: '#472e85' }}>
                Payment Successful!
              </h3>
              <p>Your payment has been successfully processed.</p>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-5 gap-3">
            <Link className="btn btn-success gradient-green light" to={`${process.env.PUBLIC_URL}/dashboard`}>Home</Link>
            <Link className="btn btn-success gradient-green" to={`${process.env.PUBLIC_URL}/receipt?id=${param1}`}>Reciept</Link>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default SuccessPage