import React, { useEffect, useState } from 'react';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { rksCustomerProfile } from '../../CommonUrl';
import { Card as BootstrapCard } from "reactstrap";

const ProfileInfoTab = () => {
  const [apiResponse, setapiResponse] = useState(null);


  useEffect(() => {
    const fetchDashboardData = async () => {
      const response = await getClientUrlDataToken(rksCustomerProfile);
      setapiResponse(response?.data);
    }
    fetchDashboardData();
  }, [])

  return (
    <div className='container-main' style={{ marginTop: '140px' }}>
      <BootstrapCard className="height-equals" style={{margin:'50px 20px 50px 20px', top:'20px'}}>
        <div className="profile-info-tab container mx-0 px-0 mt-5">
          <div className="row w-100">
          <h3 className="heading-subtitle" style={{fontWeight:'bold', fontSize:'20px', color:'#472e85'}}>Profile</h3>
            <div className="col-md-4">
              <div className="card field-card ">
                <div className="card-body">
                  <label className="field-label">Name</label>
                  <input
                    type="text"
                    className="form-control field-input"
                    value={apiResponse?.billing_name}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card field-card">
                <div className="card-body">
                  <label className="field-label">Email</label>
                  <input
                    type="email"
                    className="form-control field-input"
                    value={apiResponse?.email}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card field-card">
                <div className="card-body">
                  <label className="field-label">Phone</label>
                  <input
                    type="text"
                    className="form-control field-input"
                    value={`+${apiResponse?.country_code} ${apiResponse?.mobile}`}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card field-card">
                <div className="card-body">
                  <label className="field-label">Customer ID</label>
                  <input
                    type="text"
                    className="form-control field-input"
                    value={apiResponse?.subscriber_id}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card field-card">
                <div className="card-body">
                  <label className="field-label">Address</label>
                  <textarea
                    type="text"
                    className="form-control field-input"
                    value={apiResponse?.billing_address}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BootstrapCard>
    </div>
  );
};

export default ProfileInfoTab;
