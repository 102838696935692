import moment from "moment";
import React, { useEffect, useState } from "react";

const ExpiresOn = ({ expiresValue, expireDate, pack }) => {
  const [ParsedString, setParsedString] = useState(null);

  useEffect(() => {
    fetchBin();
    if (expiresValue?.subscriptions?.package_details) {
      const ParsedString = JSON.parse(
        expiresValue?.subscriptions?.package_details
      );
      setParsedString(ParsedString);
    }
  }, [expiresValue]);

  const matchingSubscription = expiresValue?.all_subscriptions?.find(
    (subscription) => subscription.package_id === pack
  );

  return (
    <div className="expire-info-tab container mx-0 px-0 mt-5">
      <div className="row">
        <div className="col-md-6">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Customer Name</label>
              <input
                type="text"
                className="form-control field-input"
                value={expiresValue?.first_name}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Subscriber Id</label>
              <input
                type="text"
                className="form-control field-input"
                value={expiresValue?.subscriber_id}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Bin Id</label>
              <input
                type="text"
                className="form-control field-input"
                value={expiresValue?.stb_no}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Bin Address</label>
              <textarea
                type="text"
                className="form-control field-input"
                value={expiresValue?.service_address}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="card field-card">
            <div
              className="card-body"
              style={{ boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.2)" }}
            >
              <div className="d-flex justify-content-between">
                <span className="field-label">Plan</span>
                <span className="expiry-date">Expires on {expireDate}</span>
              </div>

              <div>
                <div className="custom-field">
                  <span>
                    {ParsedString?.name} {ParsedString?.description}
                  </span>
                  <span className="price-text">{ParsedString?.price}</span>
                </div>
              </div>

              <div className="mt-4">
                <ul className="custom-bullets">
                <li>Plan Name: {matchingSubscription?.package_data?.name}</li>
                  <li>Duration: {matchingSubscription?.package_data?.duration}</li>
                  <li>Recurring: {matchingSubscription?.recurring}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiresOn;
