import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Card, Button, Label, CardBody } from "reactstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getClientUrlDataToken } from "../../Utils/restUtils";
import { guestfetchInvoice } from "../../CommonUrl";
import { useReactToPrint } from "react-to-print";
import { LI, P, UL } from "../../AbstractElements";
import Transparentspinner from "../Common/Loader/Transparentspinner";
import logo from "../../assets/images/rks-logoo.svg";
import InvoiceHeader from "../App/Ecommerce/Invoice/InvoiceHeader";
import { AmountCollected, Balance, BalanceTo, DiscountT, InvoiceTotalNo, Item, Itemtotal, Price, Quantity, Subtotal, Tax, TaxPercentage, Total, TotalPay, UnitPrice } from "../../Constant";
import InvoiceDescription from "../App/Ecommerce/Invoice/InvoiceDescription";
import InvoiceTotalAmount from "../App/Ecommerce/CheckOut/InvoiceTotal";

const RksInvoices = () => {
    const [apiResponse, setapiResponse] = useState(null);
    const [searchParams] = useSearchParams();
    const order_id = searchParams.get("id");
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();

    const contentPrint = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => contentPrint.current,
        documentTitle: "rks_invoice",
        pageStyle: `@page { size: auto; margin: 20mm; }`,
    });


    const handlePayment = (invoiceId) => {
        navigate('/pay', { state: { invoiceId } })
    }


    useEffect(() => {
        const fetchDashboardData = async () => {
            setloading(true);

            const response = await getClientUrlDataToken(
                guestfetchInvoice + `?order_id=${order_id}`
            );
            if (response?.status === true) {
                setapiResponse(response?.data);
                setloading(false);

            } else {
                setapiResponse(null);
                setloading(false);

            }
        };
        fetchDashboardData();
    }, []);

    return (
        <>
            {loading === true ? <Transparentspinner /> : (
                <div className="unpaidinvoice-container mt-5 mb-5" style={{ paddingTop: "110px" }}>
                    <div className="container">
                        <Card className="p-1">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h5 className="tab-bar m-3 text-secondary">
                                        <Link to="/account" className="text-decoration-none text-secondary">
                                            Home
                                        </Link>
                                        &nbsp;&gt;&nbsp; Invoice
                                    </h5>
                                </div>
                                <div>
                                    <button className="btn btn-white text-secondary" onClick={handlePrint}>
                                        <i className="fa-solid fa-download"></i> Download
                                    </button>
                                </div>
                            </div>
                        </Card>
                        <div ref={contentPrint}>
                            <div className='invoice invoice-print-styling' style={{ printColorAdjust: "exact" }}>
                                <Card className="p-3 mb-4">
                                    <div className='invoice invoice-print-styling'>
                                        <Row>
                                            <CardBody>
                                                <div>
                                                    <InvoiceHeader invoice={apiResponse} />
                                                    {/* <InvoiceDescription invoice={userInvoice} /> */}
                                                    <div className=" table-responsive invoice-table " id="table">
                                                        <table className='table table-bordered' >
                                                            <tbody>
                                                                <tr>
                                                                    <th className="item text-left">
                                                                        {Item}
                                                                    </th>
                                                                    <th className="Hours  text-end">
                                                                        {Quantity}
                                                                    </th>
                                                                    <th className="Rate  text-end" style={{ width: '20%' }}>
                                                                        {Price}
                                                                    </th>
                                                                    <th className="Rate  text-end">
                                                                        {DiscountT}
                                                                    </th>
                                                                    <th className="Rate  text-end">
                                                                        {TaxPercentage}
                                                                    </th>
                                                                    <th className="Rate  text-end">
                                                                        {Itemtotal}
                                                                    </th>
                                                                    <th className="Rate  text-end print-only">
                                                                        {Itemtotal}
                                                                    </th>
                                                                </tr>
                                                                {apiResponse?.order_item?.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td className=' text-left'>
                                                                                <Label className=''>{`${item.name}`}</Label>
                                                                            </td>
                                                                            <td className=' text-end'>
                                                                                <P attrPara={{ className: 'itemtext digits' }}>{item.quantity}</P>
                                                                            </td>
                                                                            <td className='text-end'>
                                                                                <P attrPara={{ className: 'itemtext digits' }}>{'B$ ' + `${item.unit_price}`}</P>
                                                                            </td>
                                                                            <td className='text-end'>
                                                                                <P attrPara={{ className: 'itemtext digits' }}>{'B$ ' + `${item?.discount ?? 0}`}</P>
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <P attrPara={{ className: '' }}>{`${item.tax ?? 0}%`}</P>
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <P attrPara={{ className: '' }}>{'B$ ' + `${item.sub_total ?? 0}`}</P>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <PrintInvoice /> */}
                                                </div>
                                                <InvoiceTotalAmount item={apiResponse} />
                                            </CardBody>
                                        </Row >
                                        <div>
                                            <div className="feedback-section text-center mt-3 pt-2 pb-2">
                                                For Feedbacks And Complaints Call:
                                                <span className='phno'>+673 2338175</span>
                                            </div>
                                            <div className="thank-you-section text-center pt-3 pb-3">
                                                Thank you choosing R.K.S.
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div>
                            {apiResponse?.payment_status !== "paid" && (
                                <Card className="p-4">
                                    <div
                                        className="mt-3"
                                        style={{
                                            backgroundColor: "#f0f0f0",
                                            padding: "20px",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <Row className="align-items-center">
                                            <Col
                                                className="text-end"
                                                style={{ paddingRight: "10px" }}
                                            >
                                                <span style={{ marginRight: "15px", fontSize: "16px" }}>
                                                    Total Payable:{" "}
                                                    <span style={{ fontWeight: "bold" }}>
                                                        B$ {apiResponse?.pending_amount}
                                                    </span>
                                                </span>
                                                <Button color="success gradient-green" style={{ marginLeft: "15px" }} onClick={() => handlePayment()}>
                                                    Pay Now
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RksInvoices;
