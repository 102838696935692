import React, { useEffect, useRef, useState } from 'react';
import { Row, Card } from 'reactstrap';
import { Link, useSearchParams } from "react-router-dom";
import { getGestDataToken } from '../../Utils/restUtils';
import { guestfetchReceipt } from '../../CommonUrl';
import { useReactToPrint } from 'react-to-print';
import Transparentspinner from '../Common/Loader/Transparentspinner';
import logo from "../../assets/images/rks-logoo.svg";

const Receipt = () => {
    const [apiResponse, setapiResponse] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [loading, setloading] = useState(false);
    const [searchParams] = useSearchParams();
    const order_id = searchParams.get("id");
    const componentRef = useRef(null);
    const handlePrintThermal = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        const fetchDashboardData = async () => {
            setloading(true);
            const response = await getGestDataToken(guestfetchReceipt + `?payment_id=${order_id}`);
            if (response?.status === true) {
                setApiData(response);
                setapiResponse(response?.data);
            } else {
                setapiResponse(null)
                setloading(false);
            }
            setloading(false);
        }
        fetchDashboardData();
    }, [])

    return (
        <>
            {loading === true ? <Transparentspinner /> : (
                <div className="receipts-container mt-5 mb-5" style={{ paddingTop: '130px' }}>
                    <div className="container">
                        {/* <Card className="p-1">
                            <h5 className="m-3 text-secondary">
                                <Link to="/dashboard" className="text-decoration-none text-secondary">
                                    Home
                                </Link>
                                &nbsp;&gt;&nbsp; Receipt
                            </h5>
                        </Card> */}

                        <Card className="p-4 mb-4" style={{ maxWidth: '500px', margin: '0 auto' }}>
                            <div className="receipt-container" ref={componentRef}>
                                <div className="receipt-box">
                                    <div className='receipt-head'>
                                        <img src={logo} alt="" className="img-logo"/>
                                        <h2 className="business-name ">{apiData?.company?.name}</h2>
                                        <p className="contact-info">+{apiData?.company?.country_code + apiData?.company?.phone}</p>
                                        <span className="dashed-line"></span>
                                        <h3 className="receipt-title mt-2 mb-2">PAYMENT RECEIPT</h3>
                                    </div>
                                    <span className="dashed-line "></span>

                                    <div className="customer-details ml-3">
                                        <h4>Customer Details:</h4>
                                        <p className='fw-medium'>{apiResponse?.created_name}</p>
                                        <p className='w-50 text-start'>
                                            {apiData?.customer?.billing_address}
                                        </p>

                                    </div>
                                    <span className="dashed-line"></span>
                                    <table className="details-table">
                                        <tbody>
                                            <tr>
                                                <td>Customer Id #</td>
                                                <td>:</td>
                                                <td className='text-end'>{apiResponse?.customer_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Receipt No #</td>
                                                <td>:</td>
                                                <td className='text-end'>{order_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Txn Id</td>
                                                <td>:</td>
                                                <td className='text-end'>{apiResponse?.transaction_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Transaction date</td>
                                                <td>:</td>
                                                <td className='text-end'>{apiResponse?.transaction_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Payment method</td>
                                                <td>:</td>
                                                <td className='text-end'>{apiResponse?.payment_method_title}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <span className="dashed-line"></span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td >Paid Amount</td>
                                                <td>:</td>
                                                <td className="highlight text-end">{'B$ ' + apiResponse?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <span className="dashed-line"></span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Bin Reflected</td>
                                                <td>:</td>
                                                <td className='text-end'>{apiResponse?.effected_bins}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Dues</td>
                                                <td>:</td>
                                                <td className='text-end'>{'B$ ' + apiResponse?.total_dues}</td>
                                            </tr>
                                            <tr>
                                                <td>Wallet Money</td>
                                                <td>:</td>
                                                <td className='text-end'>{'B$ ' + apiResponse?.wallet_money}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span className="dashed-line my-3"></span>

                                    <p className="footer-note">
                                        Your next Due/Billing date is on {apiResponse?.next_payment_date} <br />
                                        Thank you
                                    </p>

                                </div>
                            </div>
                            <div>
                                <button className="print-button" onClick={handlePrintThermal}>Print</button>
                            </div>
                            <Row>
                            </Row>
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
};

export default Receipt;
