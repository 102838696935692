import React from "react";
import {
  postDataWithToken,
} from "../../Utils/restUtils";
import {
  guestComplaintProcess,
} from "../../CommonUrl";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Update = ({ rowData, fetchComplaintData,setmodalUpdate,setisOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const boxStyle = {
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
  };

  const status = [
    { value: "request", type: "Request" },
    { value: "in_process", type: "In process" },
    { value: "processed", type: "Processed" },
  ];

  const onSubmit = async (data) => {
    const postData = {
      complaint_id: rowData?.id,
      status: data?.status,
      comments: data?.comments,
    };
    const response = await postDataWithToken(guestComplaintProcess, postData);
    if (response?.status === 200) {
      if (response?.data?.status === true) {
        toast.success(response?.data?.message);
        fetchComplaintData();
        setmodalUpdate(false);
        setisOpen(false);
      } else {
        toast.error(response?.data?.message);
      }
    }
  };

  return (
    <div className="container mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div style={boxStyle}>
              <div className="mb-3">
                <label htmlFor="status" className="form-label blue">
                  Status
                </label>
                <select
                  id="status"
                  {...register("status")}
                  className="form-select"
                >
                  <option value="" disabled selected>
                    Select a status
                  </option>
                  {status?.map((e, index) => (
                    <option key={index} value={e?.value}>
                      {e?.type}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="comment" className="form-label blue">
                  Comment
                </label>
                <textarea
                  {...register("comments")}
                  className="form-control"
                  placeholder="Add comment"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col text-center">
            <button type="submit" className="pay-all">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Update;
