import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import * as authServices from "../Utils/authServices";
import { postClientUrlWithoutToken, postDataWithoutResData } from "../Utils/restUtils";
import "react-toastify/dist/ReactToastify.css";
import { customerLogin, guestToken } from "../CommonUrl";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setRole } from "../Redux/Reducer";
import NavbarsTab from "./NavBar";
import mobImage from "../assets/images/rks-mob2.png";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const emailOrPhoneValidation = Yup.string().test(
  "is-email-or-phone",
  "Invalid email or phone number",
  (value) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phonePattern = /^\d{7,12}$/; // Assuming phone number can have 10-12 digits
    return emailPattern.test(value) || phonePattern.test(value);
  }
);

const useSchema = Yup.object().shape({
  emailOrPhone: emailOrPhoneValidation.required(
    "Email or phone number is required"
  ),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters").required("Password is required"),
});

const SignIn = () => {
  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(useSchema),
  });
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("login");
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/account");
    }
  }, [])

  useEffect(() => {
    const savedUsername = localStorage.getItem("rksCustUsername");
    const savedPassword = localStorage.getItem("rksCustPassword");

    if (savedUsername && savedPassword) {
      setValue("emailOrPhone", savedUsername);
      setValue("password", savedPassword);
      setRememberMe(true);
    }
  }, [setValue]);

  const handleRegister = () => {
    navigate("/sign-up");
  };

  const handleResetPwd = () => {
    navigate("/password-reset");
  }

  const NavigateToQuickPay = () => {
    navigate("/quick-pay");
  }

  const handleRememberMe = (e) => {
    if (e) {
      setRememberMe(true);
      const { emailOrPhone, password } = getValues();
      if (emailOrPhone && password) {
        localStorage.setItem("rksCustUsername", emailOrPhone);
        localStorage.setItem("rksCustPassword", password);
      } else {
        toast.error("Please fill in both fields to save");
        setRememberMe(false)
      }
    } else {
      setRememberMe(false);
      setValue("emailOrPhone", "");
      setValue("password", "");
      localStorage.removeItem("rksCustUsername");
      localStorage.removeItem("rksCustPassword");
    }
  }

  const onLogin = async (data) => {
    const postData = {
      email: data.emailOrPhone,
      password: data.password,
    };
    const loginResponse = await postClientUrlWithoutToken(customerLogin, postData);
    if (loginResponse?.status === 200) {
      if (loginResponse?.data?.status === true) {
        authServices.setUser(loginResponse?.data?.token);
        localStorage.setItem("rksToken", loginResponse?.data?.token);
        dispatch(setRole(loginResponse?.data?.data?.id));
        navigate("/account");
        toast.success(loginResponse?.data?.message);
      } else {
        toast.error(loginResponse?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/") {
      const GetGuestToken = async () => {
        try {
          const returnGuestToken = await postDataWithoutResData(guestToken);
          localStorage.setItem("guestToken", returnGuestToken?.data?.data?.guest_token);
          const expiresIn = returnGuestToken?.data?.data?.expires_in;
          if (expiresIn) {
            setTimeout(() => {
              GetGuestToken();
            }, expiresIn);
          }
        } catch (error) {
          console.error("Error fetching guest token:", error);
        }
      }
      GetGuestToken();
    }
  }, [location])

  return (
    <>
      <NavbarsTab />
      <div className="signin-container">
        <Row>
          <Col md='6' sm='12' className="d-flex justify-content-center">
            <Card className="sign-in-card">
              <CardBody>
                <div className="signin-form-container">
                  <h4 className="signin-heading" style={{ color: '#534686' }}>Sign in to your account</h4>
                  <p className="signin-subheading" style={{ color: '#534686' }}>
                    Smart waste management with RKS.
                    <br />
                    Join us for a healthier planet.
                  </p>
                  <form className="signin-form mt-3" onSubmit={handleSubmit(onLogin)}>
                    <div className="form-group mt-3">
                      <input type="text" placeholder="Email / Phone number" {...register("emailOrPhone")}/>
                      {errors.emailOrPhone && (
                        <p className="error-message text-danger">
                          {errors.emailOrPhone.message}
                        </p>
                      )}
                    </div>
                    <div className="password-container" style={{ position: "relative" }}>
                      <input
                        {...register("password")}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className="password-input"
                      />
                      <span
                        className="password-toggle"
                        onClick={() => setShowPassword((prev) => !prev)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "55%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? <FaRegEyeSlash size={20} /> : <FaRegEye size={20} />}
                      </span>
                      {errors.password && (
                        <p className="error-message text-danger">{errors.password.message}</p>
                      )}
                    </div>

                    <div className="signin-actions d-flex mt-2">
                      <div className="d-flex align-items-center remember-me">
                        <input
                          type="checkbox"
                          checked={rememberMe}
                          onChange={(e) => handleRememberMe(e.target.checked)}
                        />
                        <Label className="m-0 ps-2">Remember me</Label>
                      </div>
                      <div>
                        <a className="forgot-password cursor" onClick={handleResetPwd}>
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <div className="mt-3">
                      <button type="submit" disabled={loading}>
                        {loading ? "Logging in..." : "Login"}
                      </button>
                    </div>
                    <div className="register-prompt mt-2 pb-5">
                      <p>
                        Don't have an account?{" "}
                        <span
                          className="text-primary cursor"
                          onClick={handleRegister}
                        >
                          Register Here
                        </span>
                      </p>
                    </div>
                    <button
                      onClick={NavigateToQuickPay}
                      className="button-quickpay"
                      type="button"
                    >
                      Quick pay your bills
                    </button>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md='6' sm='12'>
            <div className="d-flex flex-column align-items-center text-center" style={{ minHeight: "100vh", justifyContent: "center" }}>
              <img src={mobImage} alt="mob" style={{ width: '60%' }} />
              <div style={{ display: 'flex' }}>
                <div className='footer-section1'>
                  <a href="https://play.google.com/store/apps/details?id=rks.customer.app" target="_blank">
                    <img src='/Services/Play-Store.svg' alt='' className="logo-footer" />
                  </a>
                </div>
                <div className='footer-section1'>
                  <a href="https://apps.apple.com/app/rks-brunei-customer/id1582544861" target="_blank">
                    <img src='/Services/App-Store.svg' alt='' className="logo-footer" />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default SignIn;
