import React, { Fragment, useEffect, useState } from 'react';
import { LI, UL, Btn } from '../../../../AbstractElements';
import { Col, Form, Row } from 'reactstrap';
import { Apply, CouponAmount, InvoiceTotalNo, key, key_secret, Submit } from '../../../../Constant';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { addClientPayment, addSetupCost, postCoupon } from '../../../../CommonUrl';
import { getMasterDataToken, postMasterToken } from '../../../../Utils/restUtils';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
    coupon: yup.string().required().label('Coupon'),
});

const OrderAmount = ({ datas, setLoading, details, companyId }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [showForm, setShowForm] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [couponList, setCouponList] = useState('');
    const [couponAmount, setCouponAmount] = useState(null);
    const [subTot, setSubTot] = useState(0);
    const [initialSubTot, setInitialSubTot] = useState(0);
    const [initialTotal, setInitialTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [taxAmount,setTaxAmount]=useState([]);

    useEffect(() => {
        paymentData();
    }, [])

    const paymentData = () => {
        const list = datas.data.data[0];
        const subTotWithoutString = list.setup_cost;
        const calculatePercentage = list.taxRate.reduce((acc, e) => acc + (subTotWithoutString * e.tax_percentage / 100), 0);
        const totalValue = (subTotWithoutString + calculatePercentage).toFixed(2)
        setTotal(totalValue);
        setInitialTotal(totalValue);
        setCouponList(list);
        setSubTot(subTotWithoutString);
        setInitialSubTot(list.setup_cost);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const couponApi = await getMasterDataToken(postCoupon + `?coupon_code=${data.coupon}&package_id=${details.id}`);
        if (couponApi.status === true) {
            setCouponAmount(couponApi.data.discountAmount);
            setTotal(couponApi.data.invoiceTotal);
            setSubTot(couponApi.data.subTotal);
            setTaxAmount(couponApi.data.taxes);
            setCouponCode(data.coupon)
        }
        else {
            setLoading(false);
            toast.error(couponApi.message);
        }
        setLoading(false);
    };

    const handlePayment = async () => {
    }

    const resetValues = () => {
        setCouponAmount(null);
        setSubTot(initialSubTot);
        setTotal(initialTotal);
        setTaxAmount([]);
        setValue("coupon", '');
        setCouponCode('');
    };

    const toggleForm = () => {
        if (showForm) {
            resetValues();
        }
        setShowForm(!showForm);
    };

    return (
        <Fragment>
            <Col xl="4" sm="10">
                <div className="checkout-details" style={{ backgroundColor: '#EFF3F9' }}>
                    <div className="order-box">
                        <UL attrUL={{ className: 'simple-list sub-total' }}>
                            <LI ><div className='d-flex justify-content-between billerqColor fs-5 fw-bolder'><span className='m-0'>{datas.data.data[0].name} : </span><span className="  ">{authState.currency} {datas.data.data[0].setup_cost}</span></div></LI>
                            {couponAmount != null && (
                                <LI>{CouponAmount} :<span className="count">{authState.currency} {couponAmount}</span></LI>
                            )}
                            <LI>Subtotal :<span className="count">{authState.currency} {subTot}</span></LI>
                            <LI attrLI={{ className: 'shipping-class m-0 p-0' }}>
                            </LI>
                        </UL>
                        {taxAmount.length === 0 ? (
                            <UL attrUL={{ className: 'simple-list qty' }} >
                                {couponList && couponList.taxRate.map((e) => {
                                    const taxAmount = (subTot * (e.tax_percentage / 100)).toFixed(2);
                                    return (
                                        <LI key={e.id} attrLI={{ key: e.id }}>{e.name} {e.tax_percentage}% :<span>{authState.currency} {taxAmount}</span></LI>
                                    );
                                })
                                }
                            </UL>
                        ):(
                            <UL attrUL={{ className: 'simple-list qty' }} >
                                {taxAmount.map((e,index) => {
                                    return (
                                        <LI key={index} attrLI={{ key: index }}>{e.name} {e.tax_percentage}% :<span>{authState.currency} {e.tax_amount}</span></LI>
                                    );
                                })
                                }
                            </UL>
                        )}
                        <UL attrUL={{ className: 'simple-list sub-total total' }}>
                            <LI>{InvoiceTotalNo}<span className="count">{authState.currency} {total}</span></LI>
                        </UL>
                        <div>
                            <div style={{ textDecoration: 'underline', textAlign: 'center', fontWeight: '500', cursor: 'pointer' }} onClick={toggleForm}>
                                Have a promo code?
                            </div>
                            {showForm && (
                                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="g-3 pt-3 ">
                                        <Col md='12 d-flex' >
                                            <div style={{ height: "55px" }}>
                                                <input className="form-control" style={{ padding: "11px 15px" }} placeholder='Coupon code' id="coupen" type="text" {...register('coupon')} />
                                                <span className="text-danger mt-2 " style={{ fontSize: "10px" }} >{errors.coupon?.message}</span>
                                            </div>
                                            <button className='btn btn-primary  ms-2 ' style={{ marginBottom: "13px" }} type='submit' >{Apply}</button>
                                            <div style={{ paddingLeft: '5px', marginBottom: '5px' }} onClick={toggleForm}><Trash2 className='cursor' color="#dc0808" /></div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </div>
                        <div className='mt-2 d-flex justify-content-center'>
                            <Btn attrBtn={{ color: "primary", className: "mt-3", onClick: () => handlePayment() }}>{Submit}</Btn>
                        </div>
                    </div>
                </div>
            </Col>
        </Fragment >
    );
};
export default OrderAmount;