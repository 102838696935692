import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavLink, TabContent, TabPane } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaFilter } from 'react-icons/fa';
import { guestToken, rksCustomerTransactions } from '../../CommonUrl';
import { getClientUrlDataToken, postDataWithoutResData } from '../../Utils/restUtils';

const TransactionTab = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [transactionsData, setTransactions] = useState({
        paid: [],
        unpaid: [],
    });

    const handleViewReciept = async (id) => {
        const returnGuestToken = await postDataWithoutResData(guestToken);
        localStorage.setItem("guestToken", returnGuestToken?.data?.data?.guest_token);
        navigate(`/receipt?id=${id}`);
    };

    const transactionColumns = [
        {
            name: <h4>Sl.No</h4>,
            selector: (row, index) => index + 1,
            width: '150px',
        },
        {
            name: <h4>Transaction Id</h4>,
            selector: row => row?.transaction_id,
            sortable: true,
            wrap: true,
        },
        {
            name: <h4>Paid At</h4>,
            selector: row => row?.paid_at,
            sortable: true,
            wrap: true,
        },
        {
            name: <h4>Payment Type</h4>,
            selector: row => row?.payment_method_name,
            sortable: true,
            wrap: true,
        },

        {
            name: <h4>Details</h4>,
            selector: row => row?.details,
            sortable: true,
            wrap: true,
        },


        {
            name: <h4>Amount</h4>,
            selector: row => 'B$ ' + row?.payment_amount,
            sortable: true,
            wrap: true,
        },
    ];

    const paymentHistoryColumns = [
        {
            name: <h4>No</h4>,
            selector: (row, index) => index + 1,
            width: '100px',
        },
        {
            name: <h4>Invoice Date</h4>,
            selector: row => row.paid_at,
            sortable: true,
            wrap: true,
        },
        {
            name: <h4>Txn Id</h4>,
            selector: row => row.transaction_id,
            sortable: true,
            wrap: true,
        },
        {
            name: <h4>Description</h4>,
            selector: row => row.description,
            sortable: true,
            wrap: true,
        },
        {
            name: <h4>Amount</h4>,
            selector: row => 'B$ ' + row.payment_amount,
            sortable: true,
            wrap: true,
        },
        {
            name: <h4>Account</h4>,
            selector: row => row.payment_method_name,
            sortable: true,
            wrap: true,
        },
        {
            name: <h4>Action</h4>,
            selector: row => row.action,
            sortable: true,
            cell: row => (
                <div className='d-flex'>
                    <button
                        className="btn btn-success ml-2 gradient-green"
                        style={{ padding: '1px 12px' }}
                        onClick={() => handleViewReciept(row?.pid)}
                    > Receipt </button>
                </div>
            ),
        },
    ];

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        const fetchTransactionData = async () => {
            setLoading(true);
            try {
                const paidResponse = await getClientUrlDataToken(rksCustomerTransactions + `?payment_status=paid`);
                const unpaidResponse = await getClientUrlDataToken(rksCustomerTransactions + `?payment_status=unpaid`);
                if (paidResponse?.status && unpaidResponse?.status) {
                    const paidTransactions = paidResponse?.data?.payments?.data || [];
                    const unpaidTransactions = unpaidResponse?.data?.payments?.data || [];
                    setTransactions({
                        paid: paidTransactions,
                        unpaid: unpaidTransactions,
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching transactions:", error);
            }
        }
        fetchTransactionData();
    }, [])


    return (
        <div className='container-main' style={{ marginTop: '140px' }}>
            <Card className="height-equals" style={{ margin: '50px 20px 50px 20px', top: '20px' }}>
                <Col sm="12" xl="12 xl-100">
                    <Card style={{ marginBottom: '0px' }}>
                        <CardHeader className='p-0'>
                        </CardHeader>
                        <CardBody style={{ margin: '10px 30px' }}>
                            <h3 className="heading-subtitle" style={{ fontWeight: 'bold', fontSize: '20px', color:'#472e85' }}>Transaction</h3>
                            <Nav className="text-primary border-tab nav-primary">
                                <NavLink className={`tab-link ${activeTab === '1' ? 'active' : ''}`} onClick={() => toggleTab('1')} >Transactions</NavLink>
                                <NavLink className={`tab-link ${activeTab === '2' ? 'active' : ''}`} onClick={() => toggleTab('2')}>Payment History</NavLink>
                            </Nav>

                            {/* <div className='added-titlerow'>   {activeTab === '1' ? 'Transaction Summary' : 'Payment History Summary'}</div> */}
                            <div className="added-titlerow d-flex justify-content-between align-items-center">
                                <div>{activeTab === '1' ? 'Transaction' : 'Payment History '}</div>
                                {/* <div className="icons d-flex gap-3">
                                    <FaSearch
                                        style={{ fontSize: '18px', cursor: 'pointer' }}
                                        title="Search"
                                    />
                                    <div className='vertical-line'></div>
                                    <FaFilter
                                        style={{ fontSize: '18px', cursor: 'pointer' }}
                                        title="Filter"
                                    />
                                </div> */}
                            </div>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="support-history table-responsive" >
                                        <DataTable
                                            columns={transactionColumns}
                                            data={transactionsData?.paid || []}
                                            pagination
                                            paginationServer
                                            defaultSortAsc
                                            sortServer
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            paginationDefaultPage={currentPage}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            progressPending={loading}

                                        />
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="support-history table-responsive">
                                        <DataTable
                                            columns={paymentHistoryColumns}
                                            data={transactionsData?.unpaid || []}
                                            pagination
                                            paginationServer
                                            defaultSortAsc
                                            progressPending={loading}
                                            sortServer
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            paginationDefaultPage={currentPage}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Card>
        </div>
    );
};

export default TransactionTab;