import Receipt from '../Components/Rks-customer/Receipt';
import PayNow from '../Components/Rks-customer/PayNow';
import SuccessPage from '../Components/Rks-customer/SuccessPage';
import PaymentFailed from '../Components/Rks-customer/PaymentFailed';
import RksInvoices from '../Components/Rks-customer/Invoice';
import Signup from '../A-RKS/Signup';
import ForgotPassword from '../A-RKS/ForgotPassword';
import ProfileInfoTab from '../Components/Rks-customer/ProfileInfoTab';
import ActivePlan from '../Components/Rks-customer/ActivePlan';
import InvoiceTab from '../Components/Rks-customer/InvoiceTab';
import TransactionTab from '../Components/Rks-customer/TransactionTab';
import Request from '../Components/Rks-customer/Request';
import AccountDeatails from '../Components/Rks-customer/AccountDeatails';
import QuickPayForm from '../A-RKS/QuickPayForm';

export const routes = [
  //onboarding
  // { path: `${process.env.PUBLIC_URL}/sign-up`, Component: <Signup />},
  // { path: `${process.env.PUBLIC_URL}/password-reset`, Component: <ForgotPassword />},
  // { path: `${process.env.PUBLIC_URL}/quick-pay`, Component: <QuickPayForm />},

  //navLinks
  { path: `${process.env.PUBLIC_URL}/account`, Component: <AccountDeatails />},
  { path: `${process.env.PUBLIC_URL}/profile`, Component: <ProfileInfoTab />},
  { path: `${process.env.PUBLIC_URL}/activePlan`, Component: <ActivePlan />},
  { path: `${process.env.PUBLIC_URL}/invoices`, Component: <InvoiceTab />},
  { path: `${process.env.PUBLIC_URL}/transaction`, Component: <TransactionTab />},
  { path: `${process.env.PUBLIC_URL}/support`, Component: <Request />},

  //payment
  { path: `${process.env.PUBLIC_URL}/invoice`, Component: <RksInvoices />},
  { path: `${process.env.PUBLIC_URL}/receipt`, Component: <Receipt />},
  { path: `${process.env.PUBLIC_URL}/pay`, Component: <PayNow />},
  { path: `${process.env.PUBLIC_URL}/payment-success`, Component: <SuccessPage />},
  { path: `${process.env.PUBLIC_URL}/payment-failed`, Component: <PaymentFailed />},
];
