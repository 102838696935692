import React from 'react';
import NavbarsTab from './NavBar';
import SlideShow from './SlideShow';
import Footer from './Footer';
import FancyBox from './FancyBox';
import ServicesLayout from './ServicesLayout';
import About from './About';
import ServiceDetails from './ServiceDetails';
import VideoSection from './VideoSection';
import Services3 from './Services3';
import Services4 from './Services4';
import './RksStyle.scss';
import HomeContact from './HomeContact';
import Client from './Client';
import HomePrice from './HomePrice';
import WorkingPro from './WorkingPro';
import Location from './Location';
// import './NavStyle.css';

function MainHome() {
    return (
        <>
            <NavbarsTab />
            <SlideShow />
            <FancyBox />
            <ServicesLayout />
            <About />
            <ServiceDetails />
            <VideoSection />
            <Services3 />
            <Services4 />
            {/* <HomeContact /> */}
            {/* <Client /> */}
            {/* <HomePrice /> */}
            <WorkingPro />
            <Location/>
            <Footer />
        </>
    )
}

export default MainHome