import React, { useState } from 'react';
import Popup from '../Common/Popup/popup';
import { MessageSquare } from 'react-feather';
import Update from './Update';

const ViewDetails = ({rowData,fetchComplaintData,setisOpen}) => {
  console.log('row',rowData)
  const [modalupdate, setmodalUpdate] = useState(false);

  const handleModalupdates = (e) => {
    setmodalUpdate(!modalupdate);

  }
  return (
    <div className="view-info-tab container mx-0 px-0 mt-5">
      <div className="row w-100">
        <div className="col-md-4">
          <div className="card field-card ">
            <div className="card-body">
              <label className="field-label">Customer Name</label>
              <input
                type="text"
                className="form-control field-input"
                value={rowData?.customer_name}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card field-card ">
            <div className="card-body">
              <label className="field-label">Customer Id</label>
              <input
                type="text"
                className="form-control field-input"
                value={rowData?.customer_id}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card field-card ">
            <div className="card-body">
              <label className="field-label">Date</label>
              <input
                type="text"
                className="form-control field-input"
                value={rowData?.start_date}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">IC Number</label>
              <input
                type="text"
                className="form-control field-input"
                value="N/A"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Mobile Number</label>
              <input
                type="text"
                className="form-control field-input"
                value={`+ ${rowData?.phone}`}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Bin Number</label>
              <input
                type="text"
                className="form-control field-input"
                value={rowData?.stb_no}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Request Id</label>
              <input
                type="text"
                className="form-control field-input"
                value={rowData?.complaint_no}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Request Type</label>
              <input
                type="text"
                className="form-control field-input"
                value={rowData?.problem_type}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card field-card">
            <div className="card-body">
              <label className="field-label">Comments</label>
              <input
                type="text"
                className="form-control field-input"
                value={rowData?.problem_detail}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pay-all-container mb-4">
        <div className="pay-all" onClick={() => handleModalupdates()}>
          Update
        </div>
      </div>

      <div className="viewdetails-lastcard">
        <div className="col-md-12">
          <div className="card field-card">
            {rowData &&
              rowData?.complaint_forum?.map((e,index) => (
                <div className="card-body" key={index}>
                  <div className="top-row">
                    <label className="fields-label">
                      {e?.updated_by === "Customer" ? rowData?.customer_name : "Agent"}
                    </label>
                    <div className="date-status-wrapper">
                      <p className="date-label">{e?.updated_at}</p>
                      <p className="status-progress">{e?.status === "request" ? "Request" : e?.status === "in_process" ? "In Porcess" : "Processed" }</p>
                    </div>
                  </div>

                  <div className="textarea-wrapper">
                    <div
                      className="contenteditable-textarea"
                      contentEditable="false"
                      suppressContentEditableWarning={true}
                      role="textbox"
                    >
                      {e?.comments}
                    </div>
                    <MessageSquare className="icon-message" />
                  </div>
                </div>
              ))}

            {/* <div className="card-body">
              <div className="top-row">
                <label className="fields-label">Kevin</label>
                <div className="date-status-wrapper">
                  <p className="date-label">2024-09-18 19:58</p>
                  <p className="status-inprogress">Inprogress</p>
                </div>
              </div>

              <div className="textarea-wrapper">
                <div
                  className="contenteditable-textarea"
                  ccontentEditable ="false"
                  suppressContentEditableWarning={true}
                  role="textbox"
                  // aria-placeholder="Type here..."
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                </div>
                <MessageSquare className="icon-message" />
              </div>
            </div>

            <div className="card-body">
              <div className="top-row">
                <label className="fields-label">Kevin</label>
                <div className="date-status-wrapper">
                  <p className="date-label">2024-09-18 19:58</p>
                  <p className="status-request">Request</p>
                </div>
              </div>

              <div className="textarea-wrapper">
                <div
                  className="contenteditable-textarea"
                  contentEditable ="false"
                  suppressContentEditableWarning={true}
                  role="textbox"
                  // aria-placeholder="Type here..."
            
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                </div>
                <MessageSquare className="icon-message" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Popup isOpen={modalupdate} toggler={handleModalupdates}>
        <Update
          handleModalupdates={handleModalupdates}
          rowData={rowData}
          fetchComplaintData={fetchComplaintData}
          setmodalUpdate = {setmodalUpdate}
          setisOpen = {setisOpen}
        />
      </Popup>
    </div>
  );
};

export default ViewDetails;
