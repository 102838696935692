//rks url
//signin / sign up
export const guestToken = "api/customer/guest-token"
export const guestVarify = "api/customer/verify-customer"
export const guestSendOtp = "api/customer/send-otp"
export const guestVarifyOtp = "api/customer/verify-otp"
export const guestSetPassowrd = "api/customer/password/reset"
export const guestResetPassowrd = "api/customer/password/request-reset"
export const guestQuickpayPost = "api/customer/get-bills-public"
export const guestfetchInvoice = "api/customer/view-invoice"
export const guestfetchReceipt = "api/customer/get-receipt-public"
export const guestfetchComplain = "api/customer/get-complaint"
export const guestfetchComplaintType = "api/customer/get-problem-types"
export const guestComplaintProcess = "api/customer/complaint-process"
export const guestComplaintStore = "api/customer/store-complaint"
export const guestfetchBills = "api/customer/get-bills"
export const guestfetchBins = "api/customer/bins"
export const guestfetchType = "api/customer/get-problem-types"
export const guestPaymentRequest = "api/customer/payment-request"
export const guestPaymentConfirm = "api/customer/payment-confirm/"


//dashboard
export const customerLogin = "api/customer/customer-login"
export const getDashboard = "api/customer/dashboard"
export const rksCustomerProfile = "api/customer/customer-profile"
export const rksCustomerBin = "api/customer/bins"
export const rksCustomerTransactions = "api/customer/get-payments"
export const rksCustomerInvoices = "api/customer/invoices"













export const appUrl = "https://billerq.app/";
export const Login = "login";
export const AdminLogin = "admin-login-customer";
export const SendEmail = "send-contact-us";
export const SendEmailVerification = "send-email-otp";
export const resendUrlEmail = "resend-email-otp";
export const verifyEmailOTP = 'check-email-otp';
export const resendUrlPhone = "resend-otp";
export const GetemailOtp = 'get-forgot-password-otp';
export const verifyemailOtp = 'verify-forgot-password-otp';
export const Createnewpassword = 'forgot-password-change';
export const postSubscription = 'add-client-subscription';
export const storeMultiComp = 'store-multi-company-subscription';
export const packageSubscription = 'get-client-package';
export const getLanguage = "/get-languages";
export const getPackageDetails = "get-client-package-detail";
export const postCoupon = "check-coupon-code";
export const getPackageDet = "get-package-details";
export const addSetupCost = "add-setup-cost";
export const addClientPayment = "add-client-payment";
export const getPaymentReceipt = "get-payment-receipt";
export const getSubDetail = "get-active-company-subscription";
export const CompanySubView = 'get-company-subscription';


export const getReccuring = '/admin/get-data';
export const CompanyAdd = '/admin/add-company';
export const CompanyView = '/admin/view-company';
export const EditCompany = '/admin/edit-company';
export const CompanyUpdate = '/admin/update-company';
export const CompanyRemove = '/admin/remove-company';
export const getCurrency = "/admin/get-currency-list";
export const addArea = '/admin/add-area';
export const viewArea = '/admin/view-area';
export const deleteArea = '/admin/remove-area';
export const editArea = '/admin/update-area';
export const addCustomer = '/admin/add-customer';
export const editCustomer = "/admin/update-customer";
export const viewCustomer = '/admin/show-customer';
export const viewArchiveCustomer = '/admin/get-archived-customer';
export const deleteCustomer = '/admin/remove-customer';
export const singleCustomer = '/admin/get-customer';
export const viewStb = '/admin/view-stb';
export const editStb = '/admin/edit-stb';
export const addStb = "/admin/add-stb";
export const updateStb = "/admin/update-stb";
export const deleteStb = "/admin/remove-stb";
export const postdeactivateStb = "/admin/deactivate-stb";
export const postreactivateStb = "/admin/reactivate-stb";
export const postdisconnectStb = "/admin/disconnect-stb";
export const postReconnectStb = "/admin/reconnect-stb";
export const getCustomers = "/admin/get-customer-list";
export const viewProvider = "/admin/display-provider";
export const addProvider = "/admin/store-provider";
export const deleteProvider = "/admin/delete-provider";
export const editProvider = "/admin/update-provider";
export const statusProvider = "/admin/change-provider-status";
export const getProvider = "/admin/get-provider-for-select";
export const viewAccount = "/admin/show-account";
export const viewTransaction = "/admin/get-transaction-list";
export const addAccount = "/admin/store-account";
export const editAccount = "/admin/update-account";
export const getAccount = "/admin/get-account-for-select";
export const statusAccount = "/admin/change-account-status";
export const deleteAccount = '/admin/remove-account';
export const viewPayment = "/admin/show-payment-method";
export const postDefaultPayment = "/admin/store-default-payment-method";
export const editDefaultPayment = "/admin/edit-default-payment-method";
export const addOfflinePayment = "/admin/store-payment-method";
export const deleteOfflinePayment = "/admin/remove-payment-method";
export const UpdatePayment = "/admin/update-payment-method";
export const Postagent = "/admin/add-agent";
export const Updateagent = "/admin/update-agent";
export const Deleteagent = "/admin/delete-agent";
export const addSalesOrder = "/admin/add-sales-order";
export const getDefaultPaymentMethod = "/admin/get-default-payment-method";
export const paymentMethod = "/admin/get-payment-methods-select";
export const viewPackage = "/admin/view-package";
export const addPackages = "/admin/add-package";
export const updatePackage = "/admin/update-package";
export const deletePackage = "/admin/remove-package";
export const statusPackage = "/admin/change-package-status";
export const statusSub = "/admin/toggle-recurring";
export const statusAdd = "/admin/toggle-add-on-recurring";
export const getPackage = "/admin/get-package-select";
export const getSingletbplans = "/admin/get-stb-plans";
export const addItems = "/admin/store-item";
export const viewItem = "/admin/show-item";
export const statusItem = "/admin/change-item-status";
export const editItem = "/admin/update-item";
export const deleteItem = "/admin/remove-item";
export const ViewWallet = "/admin/show-wallet";
export const AddWallet = "/admin/add-wallet";
export const viewAgent = "/admin/view-agent";
export const getArea = "/admin/get-area-select";
export const getRole = "/admin/get-role-select";
export const getUsers = "/admin/get-user-select";
export const viewRole = "/admin/view-role";
export const updateRole = "/admin/update-role";
export const editRole = "/admin/edit-role";
export const viewCategory = "/admin/get-category-data";
export const addCategory = "/admin/add-category";
export const updateCategory = "/admin/update-category";
export const statusCategory = "/admin/change-category-status";
export const deleteCategory = "/admin/remove-category";
export const getCategory = "/admin/get-category-select";
export const getCompany = "/admin/get-company-change-data";
export const changeCompany = "/admin/change-company";
export const changePassword = "/admin/update-staff-password";
export const viewTaxGroup = "/admin/get-tax-groups";
export const taxGroupStatus = "/admin/tax-group-status-change";
export const viewTax = "/admin/get-tax";
export const statusTax = "/admin/get-tax-select";
export const addTaxGroup = "/admin/add-tax-group";
export const getpaymentId = "/admin/get-payment-receipt";
export const addTax = "/admin/add-tax";
export const taxRateedit = "/admin/edit-tax";
export const taxRateupdate = "/admin/update-tax";
export const taxRateremove = "/admin/remove-tax";
export const updateTaxGroup = "/admin/update-tax-group";
export const getProblemType = "/admin/get-problem-types";
export const addComplaint = "/admin/store-complaint";
export const updateComplaint = "/admin/complaint-process";
export const addAddon = "/admin/store-add-on";
export const viewAddon = "/admin/show-add-on";
export const updateAddon = "/admin/update-add-on";
export const deleteAddon = "/admin/remove-add-on";
export const statusAddon = "/admin/change-add-on-status";
export const getAddon = "/admin/get-add-on-select";
export const getComplaint = "/admin/get-complaint";
export const changecutomerstatus = "/admin/change-customer-status";
export const viewSubscription = "/admin/show-customer-subscription";
export const activatesublist = "/admin/get-pending-subscription-list";
export const activatesub = "/admin/activate-subscription";
export const getcategoryincome = "/admin/get-category/income";
export const getcategoryexpence = "/admin/get-category/expense";
export const getcategoryitems = "/admin/get-category/item";
export const getitemSelect = "/admin/get-item-select";
export const getitemdeatils = "/admin/get-selected-item-detail";
export const searchcustomer = "/admin/get-customer-search";
export const getstatscount = "/admin/get-customer-status-wise-count";
export const deleteSubscription = "/admin/remove-add-on";
export const getCustomerDetails = "/admin/get-customer-profile";
export const getUserStb = "/admin/get-single-customer-stb";
export const getstatscountStb = "/admin/stb-status-count";
export const viewInvoiceTable = '/admin/show-order';
export const viewCancelInvoice = '/admin/cancelled-invoice';
export const restoreInvoice = '/admin/restore-invoice';
export const viewSingleInvoice = '/admin/show-single-customer-order';
export const viewSingleSubscription = '/admin/get-single-subscription';
export const viewSingleAddon = '/admin/show-single-customer-add-on';
export const getDashboardBottomData = "/admin/get-data";
export const getDashboardData = "/admin/get-connection-data";
export const getPermission = "/admin/show-permission";
export const postPermission = "/admin/add-role";
export const addOrder = "/admin/store-order";
export const addCustomerSubscription = "/admin/store-customer-subscription";
export const addBulkCustomerSubscription = "/admin/bulk-package-change";
export const getStb = '/admin/stb-select';
export const selectCustStb = '/admin/get-customer-stb';
export const viewPackageReport = '/admin/get-package-report';
export const viewWalletReport = '/admin/get-wallet-report';
export const viewTaxReport = '/admin/get-tax-report';
export const viewAddonReport = '/admin/get-add-on-report';
export const viewSubscriptionReport = '/admin/get-subscription-report';
export const getCollectionReport = '/admin/get-agent-collection-report';
export const getCustomerPaymentReport = '/admin/get-customer-payment-report';
export const getUnpaidCustomerReport = '/admin/get-unpaid-customers';
export const getCollectionDue = '/admin/get-payment-due-data';
export const addInvoicePayment = "/admin/store-payment";
export const getOnlinePayment = '/admin/get-online-payment-data';
export const getRecentPayment = '/admin/get-recent-payment';
export const getOrderRecentPayment = '/admin/get-order-recent-payment';
export const getRecentInvoice = '/admin/get-recent-order';
export const reversePayment = '/admin/reverse-transaction';
export const getWalletBalance = '/admin/get-wallet-report';
export const getSubHistory = '/admin/customer-subscription-history';
export const getInvoiceData = '/admin/get-invoice-data-show';
export const sendOrderID = '/admin/get-invoice-data-show';
export const getAddonHistory = '/admin/customer-add-on-history';
export const Changepassword = '/admin/change-customer-password';
export const recurringApiConnection = "/admin/do-recurring";
export const deactivateDetails = '/admin/get-stb-details';

export const publicSampleDownloadCustomer = 'https://customer.billerq.com/public/storage/import_files/customer_import.xlsx';
export const publicSampleDownloadWallet = 'https://customer.billerq.com/public/storage/import_files/wallet_import.xlsx';
export const publicSampleDownloadCustomerDue = 'https://customer.billerq.com/public/storage/import_files/customer_dues_import.xlsx';
export const postCustomerImport = '/admin/customer-import';
export const postWalletImport = '/admin/import-wallet';
export const postCustomerImportDue = '/admin/customer-dues-import';
export const getPaymentHistory = '/admin/get-customer-payment-history';
export const getCustomerImportedLog = '/admin/customer-import-report';
export const getCustomerDueImportedLog = '/admin/import-dues-log';
export const getWalletDueImportedLog = '/admin/wallet-import-report';
export const deleteCustomerImportedLog = '/admin/delete-customer-import';
export const deleteCustomerDueImportedLog = '/admin/delete-customer-due-import';
export const deleteWalletDueImportedLog = '/admin/delete-wallet-import';
export const postImportPackageApi = '/admin/do-package-import';
export const publicSampleDownloadpackage = 'https://customer.billerq.com/public/storage/import_files/package_import.xlsx';
export const postImportAddonApi = '/admin/do-add-on-import';
export const publicSampleDownloadaddon = 'https://customer.billerq.com/public/storage/import_files/add_on_import.xlsx';
export const postImportItemApi = '/admin/do-item-import';
export const publicSampleDownloaditem = 'https://customer.billerq.com/public/storage/import_files/items_import.xlsx';
export const recentPayment = 'admin/get-recent-payment ';
export const paymentSelectbox = '/admin/get-invoice-amount';
export const paymentformtype = '/admin/get-box-payment';
export const deleteInvoice  ='/admin/delete-invoice';
export const getstatscountcomplaints = "/admin/complaint-status-count";
export const getTaxitems = "/admin/get-tax-rate-name";
// header
export const getHeaders = "/admin/get-header";
export const addHeaders = "/admin/store-header";
export const updateHeaders = "/admin/update-header";
export const editHeaders = "/admin/edit-header";
export const deleteHeaders = "/admin/delete-header";
export const statusHeaders = "/admin/change-header-status";
export const selectHeaders = "/admin/get-header-select";

// vendor
export const getVendor = "/admin/get-vendor";
export const addVendor = "/admin/store-vendor";
export const updateVendor = "/admin/update-vendor";
export const deleteVendor = "/admin/delete-vendor";
export const statusVendor = "/admin/change-vendor-status";
export const editVendor = "/admin/edit-vendor";
export const selectVendor = "/admin/get-vendor-select";



// Expense
export const getExpense = "/admin/get-expense";
export const getExpenseRole = "/get-expense";
export const addExpense = "/admin/add-expense";
export const addExpenseRole = "/add-expense";
export const updateExpense = "/admin/update-expense";
export const updateExpenseRole = "/update-expense";
export const deleteExpense = "/admin/delete-expense";
export const deleteExpenseRole = "/delete-expense";
export const editExpense = "/admin/edit-expense";
export const editExpenseRole = "/edit-expense";
export const addexpensePayment = "/admin/add-expense-payment";
export const expenseDetails = "/admin/get-expense-details";
export const expenseDetailsRole = "/get-expense-details";

// income
export const getIncome = "/admin/get-income";
export const getIncomeRole = "/get-income";
export const addIncome = "/admin/add-income";
export const addIncomeRole = "/add-income";
export const updateIncome = "/admin/update-income";
export const updateIncomeRole = "/update-income";
export const editIncome = "/admin/edit-income";
export const editIncomeRole = "/edit-income";
export const deleteIncome = "/admin/delete-income";
export const deleteIncomeRole = "/delete-income";
export const addincomePayment = "/admin/add-income-payment";
export const incomeDetails = "/admin/get-income-details";
export const incomeDetailsRole = "/get-income-details";


// Master
export const getMasterDetails = "get-client-profile-data";
export const getMasterProfile = "edit-client-data";
export const updateMasterProfile = "update-client-data";
export const getMasterSubscription = "get-user-subscription-list";
export const getMasterInvoice = "get-company-invoices";
export const getShareApi = "get-order-number";
export const getMasterPayment = "get-company-payment";


export const getCompanyDetails = "/admin/user-connection-data";
export const getCompanyProfile = "/admin/get-company-data";
export const getCompanySubscription = "get-user-subscription";
export const getCompanyInvoice = "get-user-invoices";
export const getCompanyPayment = "get-payment-list";


export const getDashboardBottomDataRole = "/get-data";
export const searchcustomerRole = "/get-customer-search";
export const getDashboardDataRole = "/get-connection-data";
export const viewCustomerRole = '/show-customer';
export const getstatscountRole = "/get-customer-status-wise-count";
export const singleCustomerRole = '/get-customer';
export const addCustomerRole = '/add-customer';
export const editSingleCustomer = '/edit-customer';
export const editCustomerRole = "/update-customer";
export const viewAreaRole = '/view-area';
export const addAreaRole = '/add-area';
export const editAreaRole = '/update-area';
export const deleteAreaRole = '/remove-area';
export const statusProviderRole = "/change-provider-status";
export const viewProviderRole = "/display-provider";
export const deleteProviderRole = "/delete-provider";
export const addProviderRole = "/store-provider";
export const editProviderRole = "/update-provider";
export const statusCategoryRole = "/change-category-status";
export const deleteCategoryRole = "/remove-category";
export const viewCategoryRole = "/get-category-data";
export const addCategoryRole = "/add-category";
export const deleteOfflinePaymentRole = "/remove-payment-method";
export const viewPaymentRole = "/show-payment-method";
export const getAccountRole = "/get-account-for-select";
export const viewAccountRole = "/show-account";
export const addOfflinePaymentRole = "/store-payment-method";
export const UpdatePaymentRole = "/update-payment-method";
export const getComplaintRole = "/agent-get-complaint";
export const postDefaultPaymentRole = "/store-default-payment-method";
export const editDefaultPaymentRole = "/edit-default-payment-method";
export const getCustomersRole = "/get-customer-list";
export const getProblemTypeRole = "/get-problem-types";
export const addComplaintRole = "/store-complaint";
export const updateComplaintRole = "/complaint-process";
export const deleteCustomerRole = '/remove-customer';
export const getProviderRole = "/get-provider-for-select";
export const viewStbRole = '/view-stb';
export const addStbRole = "/add-stb";
export const updateStbRole = "/update-stb";
export const viewTransactionRole = "/get-transaction-list";
export const postdeactivateStbRole = "/deactivate-stb";
export const postreactivateStbRole = "/reactivate-stb";
export const postdisconnectStbRole = "/disconnect-stb";
export const postReconnectStbRole = "/reconnect-stb";
export const ViewWalletRole = "/show-wallet";
export const paymentMethodRole = "/get-payment-methods-select";
export const AddWalletRole = "/add-wallet";
export const viewAddonRole = "/show-add-on";
export const statusTaxRole = "/get-tax-select";
export const addAddonRole = "/store-add-on";
export const updateAddonRole = "/update-add-on";
export const viewInvoiceTableRole = '/show-order';
export const viewSubscriptionRole = "/show-customer-subscription";
export const getStbRole = '/stb-select';
export const getCategoryRole = "/get-category/income";
export const getPackageRole = "/get-package-select";
export const addCustomerSubscriptionRole = "/store-customer-subscription";
export const getAddonRole = "/get-add-on-select";
export const viewPackageRole = "/view-package";
export const getAreaRole = "/get-area-select";

export const statusPackageRole = "/change-package-status";
export const statusSubRole = "/toggle-recurring";
export const statusAddRole = "/toggle-add-on-recurring";
export const addPackagesRole = "/add-package";
export const updatePackageRole = "/update-package";
export const viewPackageReportRole = '/get-package-report';
export const viewWalletReportRole = '/get-wallet-report';
export const getOnlinePaymentRole = '/get-online-payment-data';
export const getCollectionReportRole = '/get-agent-collection-report';
export const getCollectionDueRole = '/get-payment-due-data';
export const viewAddonReportRole = '/get-add-on-report';
export const viewSubscriptionReportRole = '/get-subscription-report';
export const viewTaxReportRole = '/get-tax-report';
export const getTaxitemsRole = "/get-tax-rate-name";
export const paymentSelectboxRole = '/get-invoice-amount';
export const addInvoicePaymentRole = "/store-payment";
export const getpaymentIdRole = "/get-payment-receipt";
export const getCompanyRole = "/get-company-change-data";
export const getstatscountStbRole = "/stb-status-count";
export const getCustomerDetailsRole = "/get-customer-profile";
export const getUserStbRole = "/get-single-customer-stb";
export const viewSingleSubscriptionRole = '/get-single-subscription';
export const viewSingleAddonRole = '/show-single-customer-add-on';
export const getSubHistoryRole = '/customer-subscription-history';
export const getAddonHistoryRole = '/customer-add-on-history';
export const getRecentInvoiceRole = '/get-recent-order';
export const getRecentPaymentRole = '/get-recent-payment';
export const getPaymentHistoryRole = '/get-customer-payment-history';
export const getInvoiceDataRole = '/get-invoice-data-show';
export const getOrderRecentPaymentRole = '/get-order-recent-payment';
export const paymentformtypeRole = '/get-box-payment';
export const statusAccountRole = "/change-account-status";
export const editStbRole = '/edit-stb';
export const selectCustStbRole = '/get-customer-stb';
export const getSingletbplansRole = "/get-stb-plans";
export const getitemSelectRole = "/get-item-select";
export const getcategoryincomeRole = "/get-category/income";
export const getitemdeatilsRole = "/get-selected-item-detail";
export const addSalesOrderRole = "/add-sales-order";
export const getstatscountcomplaintsRole = "/complaint-status-count";
export const getUsersRole = "/get-user-select";
export const deletePackageRole = "/remove-package";
export const getcategoryitemsRole = "/get-category/item";
export const deleteItemRole = "/remove-item";
export const editItemRole = "/update-item";
export const statusAddonRole = "/change-add-on-status";
export const deleteAddonRole = "/remove-add-on";
export const addItemRole = "/store-item";
export const viewItemRole = "/show-item";
export const statusItemRole = "/change-item-status";
export const getCustomerPaymentReportRole = '/get-customer-payment-report';
export const getUnpaidCustomerReportRole = '/get-unpaid-customers';
export const getHeaderRole = "/get-header";
export const editHeaderRole = "/edit-header";
export const addHeaderRole = "/store-header";
export const updateHeadersRole = "/update-header";
export const deleteHeadersRole = "/delete-header";
export const statusHeadersRole = "/change-header-status";
export const selectHeadersRole = "/get-header-select";
export const getVendorRole = "/get-vendor";
export const addVendorRole = "/store-vendor";
export const updateVendorRole = "/update-vendor";
export const deleteVendorRole = "/delete-vendor";
export const statusVendorRole = "/change-vendor-status";
export const editVendorRole = "/edit-vendor";
export const selectVendorRole = "/get-vendor-select";
export const changecutomerstatusRole = "/change-customer-status";
export const deleteStbRole = "/remove-stb";
export const deactivateDetailsRole = '/get-stb-details';
export const addBulkCustomerSubscriptionRole = "/bulk-package-change";
export const recurringApiConnectionRole = "/do-recurring";
export const postImportPackageApiRole = '/do-package-import';
export const postImportItemApiRole = '/do-item-import';
export const postImportAddonApiRole = '/do-add-on-import';
export const addAccountRole = "/store-account";
export const editAccountRole = "/update-account";
export const deleteAccountRole = '/remove-account';
export const updateCategoryRole = "/update-category";

export const getInvoice = 'get-shared-invoice';
export const getGeneratePayment = "generate-public-payment";
export const getMakePayment = "make-public-payment";
