import React, { Fragment, useEffect, useState } from 'react';
import { LI, UL } from '../../../../AbstractElements';
import { Col } from 'reactstrap';
import { AmountCollected, InvoiceTotalNo, Prevdues, Previous, PreviousDue, Subtotal, Tax, TotalDues, WalletMoney } from '../../../../Constant';

const InvoiceTotalAmount = (props) => {
    const [historyAmount, sethistoryAmount] = useState(0)
    const invoiceValue = props.item;
    const currency='B$';
    // useEffect(() => {
    //     setcurrency(invoiceValue.currency_symbol);
    //    const payment_hitory_amount = invoiceValue.payment_history.reduce((prev,cur) => prev + cur.collected_amount,0);
    //    sethistoryAmount(payment_hitory_amount)
    // }, [])

    return (
        <Fragment>
            <Col>
                <div className="checkout-details d-flex justify-content-end" >
                    <div className="order-box">
                        <UL  >
                            <LI >
                                {Subtotal}<span>{currency}{invoiceValue?.sub_total}</span>
                            </LI>
                            <LI >
                                {InvoiceTotalNo}<span>{currency}{invoiceValue?.order_total}</span>
                            </LI>
                            {/* <LI>
                                {PreviousDue}<span>{invoiceValue?.total_tax}</span>
                            </LI> */}
                            <LI >{Prevdues} <span>{currency}{invoiceValue?.payment_due}</span></LI>
                            <LI ><strong>{TotalDues} <span classNamestyle='payable-dues'>{currency}{invoiceValue?.pending_amount}</span></strong></LI>
                            <LI >{AmountCollected} <span>{currency}{historyAmount}</span></LI>
                            <LI >{WalletMoney} <span>{currency}{invoiceValue?.wallet_money}</span></LI>
                        </UL>
                    </div>
                </div>
            </Col>
        </Fragment >
    );
};
export default InvoiceTotalAmount;