import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import SignIn from "../A-RKS/SignIn";
import PrivateRoute from "./PrivateRoute";
import SignUp from "../Billerq/Sign/SignUp";
import PhoneOtpSuccess from "../Billerq/Sign/PhoneOTPSuccess";
import EmailOtpSuccess from "../Billerq/Sign/EmailOtpSuccess";
import PriceSec from "../Billerq/Sign/PriceSec";
import CompanySection from "../Billerq/AddCompany/CompanySection";
import SubscriptionSection from "../Billerq/Subscription/AddSubscription";
import ApplyCoupon from "../Billerq/Subscription/ApplyCoupon";
import BillSuccess from "../Billerq/Subscription/BillSuccess";
import Disclaimers from "../Billerq/Disclaimers";
import TermsCondition from "../Billerq/TermsConditon";
import PrivacyPolicy from "../Billerq/PrivacyPolicy";
import Invoice from "../Components/InvoiceExternal/Invoice";
import CancelAndRefund from "../Billerq/CancelAndRefund";
import ShipAndDelivery from "../Billerq/ShipAndDelivery";
import CompSubscription from "../Billerq/Subscription/CompSubscription";
import Error from "../Billerq/Error/Error";
import Loading from "../Billerq/Sign/Loading";
import PhoneOtp from "../Billerq/Sign/PhoneOTP";
import Receipt from "../Components/Rks-customer/Receipt";
import ViewDetails from "../Components/Rks-customer/ViewDetails";
import Signup from "../A-RKS/Signup";
import ForgotPassword from "../A-RKS/ForgotPassword";
import Card from "../Billerq/Card";
import { isLoggedIn } from "../Utils/authServices";
import PayNow from "../Components/Rks-customer/PayNow";
import QuickPayForm from "../A-RKS/QuickPayForm";
import MainHome from "../A-RKS/MainHome";
import ScrollToTop from "./ScrollTop";


const Routers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', location.pathname);

    const loginStatus = localStorage.getItem("login");
    const isUserLoggedIn = loginStatus && loginStatus !== "false";

    if (!isUserLoggedIn && location.pathname === "/dashboard") {
      navigate("/login", { replace: true });
    }
  }, [location, navigate]);

  const appVersion = 'rks_version 1';
  const savedVersion = localStorage.getItem('rks_version');


  if (savedVersion !== appVersion) {
    localStorage.clear();
    localStorage.setItem('rks_version', appVersion);
    window.location.reload();
  }


  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<SignIn />} />
        <Route path='sign-up' element={<Signup />} />
        <Route path='password-reset' element={<ForgotPassword />} />
        {/* <Route path="receipt-public" element={<Receipt />} />
        <Route path="view-details" element={<ViewDetails />} /> */}
        <Route path="quick-pay" element={<QuickPayForm />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
            <LayoutRoutes />
            </PrivateRoute>
          }
        />

        <Route
          path="/"
          element={
            localStorage.getItem("login") ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {authRoutes.map(({ path, Component }, i) => (
          <Route path={path} element={Component} key={i} />
        ))}
      </Routes>
    </Suspense>
  );
};

const Router = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routers />
    </BrowserRouter>
  );
};
export default Router;
