import React from 'react'
import { Card, CardBody } from 'reactstrap'
import Paymentfailed from '../../assets/images/PayentFailed1.png';
import { Link } from 'react-router-dom';
const PaymentFailed = () => {
  return (
    <Card className="payment-success" style={{ marginBottom: '0px', borderRadius: '0px' }}>
      <CardBody>
        <div>
          <div className="d-flex flex-column align-items-center text-center" style={{ justifyContent: "center", marginBottom:'40px' }}>
            <img src={Paymentfailed} alt="" />
            <div>
              <h3 className="heading-subtitle" style={{ fontWeight: 'bold', fontSize: '20px', color: '#472e85' }}>
                Payment Failed!
                </h3>
                <p>Payment could not be processed. 
                Check details and try again.</p>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-5 gap-3">
            <Link className="btn btn-success gradient-green light" to={`${process.env.PUBLIC_URL}/dashboard`}>Home</Link>
            <Link className="btn btn-success gradient-green light" to={`${process.env.PUBLIC_URL}/pay`}>Retry</Link>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default PaymentFailed