import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// import './billerqStyle.scss';
import { Smartphone, Phone, Mail, MapPin } from 'react-feather';
import logo from "../assets/images/rks-logoo.svg";
import { FaFacebook, FaInstagram, FaPhoneAlt } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import whiteLogo from '../assets/images/logo-white.svg';
import playStore from '../assets/images/apps/google-play.png';
import appStore from '../assets/images/apps/app-store.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3">
                            <div className="footer-widget-about">
                                <img src={whiteLogo} alt="logo" style={{ paddingBottom: '20px', width: '180px' }} />
                                <p className="footer-widget-desc color-gray-400">
                                    We recognize that the right service for home is the most important chosie, RKS can
                                    provide the waste collection you need for your home, with trusted service.
                                </p>
                                <ul className="social-icons list-unstyled mb-0">
                                    <li><a href="https://m.facebook.com/61573423114653/">
                                        <div className="social-icon d-flex justify-content-center align-item-center">
                                            <FaFacebook size={22} />
                                        </div>
                                    </a></li>
                                    <li><a href="https://www.instagram.com/p/DG2UUOzJWyF/">
                                        <div className="social-icon d-flex justify-content-center align-item-center">
                                            <FaInstagram size={22} />
                                        </div>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="footer-widget-nav">
                                <h6 className="footer-widget-title">Company</h6>
                                <nav>
                                    <ul className="list-unstyled">
                                        <li><a href="https://rksbrunei.com/about-us.php">About Us</a></li>
                                        <li><a href="https://rksbrunei.com/services.php">Services</a></li>
                                        <li><a href="https://rksbrunei.com/pricing.php">Pricing</a></li>
                                        <li><a href="https://rksbrunei.com/contact-us.php">Contact Us</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="footer-widget-nav">
                                <h6 className="footer-widget-title">Services</h6>
                                <nav>
                                    <ul className="list-unstyled">
                                        <li><a href="https://rksbrunei.com/services.php">Waste Collection & Disposal</a></li>
                                        <li><a href="https://rksbrunei.com/services.php">Cleaning Services</a></li>
                                        <li><a href="https://rksbrunei.com/services.php">Grass Cutting & Landscaping</a></li>
                                        <li><a href="https://rksbrunei.com/services.php">Transportation Services</a></li>
                                        <li><a href="https://rksbrunei.com/services.php">Product Supply</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="footer-widget-nav">
                                <h6 className="footer-widget-title">Download the App Today and Get Started!</h6>
                                <a href="https://apps.apple.com/app/rks-brunei-customer/id1582544861" target="_blank">
                                    <img src={appStore} alt="logo" style={{ paddingBottom: '20px', width: '180px' }} />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=rks.customer.app" target="_blank">
                                    <img src={playStore} alt="logo" style={{ paddingBottom: '20px', width: '180px' }} />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="footer-widget-contact">
                                <h6 className="footer-widget-title">Quick Contacts</h6>
                                <p className="color-gray-400">If you have any questions or need help, feel free to contact with our team.
                                </p>
                                <ul className="contact-list list-unstyled">
                                    <li>
                                        <a href="mailto: support@rksbrunei.com">
                                            <IoMdMail size={25} /> <span style={{ paddingLeft: '10px' }}>support@rksbrunei.com</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+673 2338175">
                                            <FaPhoneAlt size={20} /><span style={{ paddingLeft: '10px' }}>+673 2338175</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="contact-address" href="https://maps.app.goo.gl/7hCbBNe2xkQ4649M9">Unit 4, Block A, First Floor, Aman Complex, Jalan Aman, Jalan Muara, Kampong Sungai Tilong, Bandar Seri Begawan, Brunei</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-secondary">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-column align-items-center text-center">
                            {/* <button class="scroll-btn" id="scrollTopBtn">
                                <i class="btn-icon icon-arrow-up-long"></i>
                                <span class="btn-text">Back To Top </span>
                            </button> */}
                            <nav>
                                <ul className="list-unstyled footer-copyright-links d-flex flex-wrap justify-content-center">
                                    <li><a href="#">Privacy Statement</a></li>
                                    <li><a href="#">Terms of Use</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Sitemap</a></li>
                                </ul>
                            </nav>
                            <div className="copyright-text">
                                <span>&copy; 2025 RKS Syed Mohammed Enterprise, All Rights Reserved. Powered By </span>
                                <a href="https://quadleo.com" className="color-secondary">Quadleo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
