import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Btn, H3, LI, P, UL } from '../../AbstractElements';
import { Item, Price, Itemtotal, TaxPercentage, setup_cost, LogOut, OrderSummery, OrderSummary } from '../../Constant';
import { Col, Label, Row, Container, CardBody, Card, Form } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPackageDetails } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { postMasterToken } from '../../Utils/restUtils';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';
import { useForm } from 'react-hook-form';
import HandleHomePageError from '../../Components/Common/ErrorImport/SignError';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import NavSign from '../../A-RKS/NavBar';
import OrderAmount from '../../Components/App/Ecommerce/CheckOut/OrderAmount';
import PackageDetail from '../../Components/App/Ecommerce/CheckOut/PackageDetail';


const schema = yup.object().shape({
    coupon: yup.string().required().label('Coupon'),
});


const ApplyCoupon = () => {
    const location = useLocation();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const { package_id, company_id } = location.state;
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [couponList, setCouponList] = useState('');
    const [taxPer, setTaxPer] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataList,setDataList]=useState({});

    useEffect(() => {
        paymentData();
    }, [])


    const paymentData = async () => {
        setLoading(true);
        const data = await postMasterToken(getPackageDetails, { package_id: package_id.id, industry_id: authState.industryId });
        if (data.status === 200) {
            setDataList(data);
            const list = data.data.data[0];
            setCouponList(list);    
            const totalTaxPercentage = list.taxRate.reduce((accumulator, current) => accumulator + current.tax_percentage, 0);
            setTaxPer(totalTaxPercentage);
        }
        else {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            HandleHomePageError(navigate, data.status)
        }
        setLoading(false);
    };


    return (
        <div>
            <NavSign showProfile={true} buttonText={LogOut} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#eff3f9",
                    height: "100%",
                }}
            >
                <Fragment>
                    {loading && <Transparentspinner />}
                    <Container fluid={true} style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '35px' }}>
                        <Card style={{ width: '90vw' }} className="checkout">
                            <CardBody>
                                <div className='invoice invoice-print-styling'>
                                    <Row>
                                        <H3>{OrderSummary}</H3>
                                        <CardBody  >
                                            <Row className='d-flex justify-content-center'>
                                                <PackageDetail 
                                                details={package_id} 
                                                features_list = {couponList}
                                                tax_percentage = {taxPer}
                                                />
                                                {Object.keys(dataList).length > 0 &&
                                                 <OrderAmount 
                                                 details={package_id} 
                                                 datas={dataList} 
                                                 setLoading = {setLoading}
                                                 companyId={company_id}
                                                 />}
                                            </Row >
                                        </CardBody>
                                    </Row >
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </Fragment>
            </div>
        </div>
    );
};

export default ApplyCoupon;