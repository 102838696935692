import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import PayNow from "./PayNow";
import ViewDetails from "./ViewDetails";
import Popup from "../Common/Popup/popup";
import { FaSearch, FaFilter } from "react-icons/fa";
import { rksCustomerInvoices } from "../../CommonUrl";
import { getClientUrlDataToken } from "../../Utils/restUtils";
import { H3 } from "../../AbstractElements";

const InvoiceTab = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const [modalpay, setmodalPay] = useState(false);
  const [invoiceData, setInvoices] = useState({
    paid: [],
    unpaid: [],
  });
  const [loading, setLoading] = useState(false)


  const handleViewInvoice = (id) => {
    navigate(`/invoice?id=${id}`);
  };

  const handleModalpay = (e) => {
    setmodalPay(!modalpay);
  };

  const invoicepaidColumns = [
    {
      name: <h4>Sl.No</h4>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <h4>Invoice Number</h4>,
      selector: (row) => row.invoice_number,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Invoice Date</h4>,
      selector: (row) => row.invoice_date,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Due/Start Date</h4>,
      selector: (row) => row.due_date,
      sortable: true,
      wrap: true,
    },

    {
      name: <h4>Invoice Status</h4>,
      selector: (row) => row.payment_status,
      sortable: true,
      cell: (row) => (
        <span
          className={`badge ${row.payment_status === "paid" ? "badge-success" : "badge-success"
            }`}
          style={{
            backgroundColor: "#ECFDF5",
            color: row.payment_status === "paid" ? "#10B981" : "",
            fontWeight: 400,
            textTransform: "capitalize",
          }}
        >
          {row.payment_status}
        </span>
      ),
      center: true,
    },

    {
      name: <h4>Amount</h4>,
      selector: (row) => 'B$ ' + row.amount,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Action</h4>,
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div className="d-flex">
          <span
            onClick={() => handleViewInvoice(row?.id)}
            className="btn btn-link"
            style={{ color: "#534686", padding: "0.25rem 0.5rem" }}
          >
            View
          </span>
        </div>
      ),
    },
  ];

  const invoiceUnpaidColumns = [
    {
      name: <h4>Sl.No</h4>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <h4>Invoice Number</h4>,
      selector: (row) => row.invoice_number,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Invoice Date</h4>,
      selector: (row) => row.invoice_date,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Due/Start Date</h4>,
      selector: (row) => row.due_date,
      sortable: true,
      wrap: true,
    },

    {
      name: <h4>Invoice Status</h4>,
      selector: (row) => row.payment_status,
      sortable: true,
      cell: (row) => (
        <span
          className={`badge ${row.payment_status !== "paid" ? "badge-danger" : "badge-danger"
            }`}
          style={{
            backgroundColor: "#FEF2F2",
            color: row.payment_status !== "paid" ? "#DF2935" : "",
            fontWeight: 400,
            textTransform: "capitalize",
          }}
        >
          {row.payment_status}
        </span>
      ),
      center: true,
    },

    {
      name: <h4>Amount</h4>,
      selector: (row) => 'B$ ' + row.amount,
      sortable: true,
      wrap: true,
    },
    {
      name: <h4>Action</h4>,
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div className="d-flex">
          <span
            onClick={() => handleViewInvoice(row?.id)}
            className="btn btn-link"
            style={{ color: "#534686", padding: "0.25rem 0.5rem" }}
          >
            View
          </span>

          {row.payment_status !== "paid" && (
            <button
              className="btn btn-success gradient-green ml-2"
              style={{ padding: "1px 12px" }}
              onClick={() => handlePayment(row)}
            >
              Pay
            </button>
          )}
        </div>
      ),
      center: true,
    },
  ];

  const handlePayment = (invoiceId) => {
    navigate('/pay', { state: { invoiceId } })
  }



  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const ActionCell = ({ row }) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          color="#00000"
          style={{ fontSize: "14px", zIndex: 1000 }}
        >
          <i className="fa fa-ellipsis-v"></i>
        </DropdownToggle>
      </Dropdown>
    );
  };

  const fetchDashboardData = async () => {
    setLoading(true);

    try {
      const paidResponse = await getClientUrlDataToken(
        rksCustomerInvoices + `?payment_status=paid`
      );

      const unpaidResponse = await getClientUrlDataToken(
        rksCustomerInvoices + `?payment_status=unpaid`
      );

      if (paidResponse?.status && unpaidResponse?.status) {
        const paidTransactions = paidResponse?.data?.data || [];
        const unpaidTransactions = unpaidResponse?.data?.data || [];

        setInvoices({
          paid: paidTransactions,
          unpaid: unpaidTransactions,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching transactions:", error);
    }
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <div className='container-main' style={{ marginTop: '140px' }}>
      <Card className="height-equals" style={{ margin: '50px 20px 50px 20px', top: '20px' }}>
        <Col sm="12" xl="12 xl-100">
          <Card style={{ marginBottom: '0px' }}>
            <CardHeader className="p-0">
            </CardHeader>
            <CardBody style={{ margin: '10px 30px' }}>
              <h3 className="heading-subtitle" style={{ fontWeight: 'bold', fontSize: '20px', color: '#472e85' }}>Invoice</h3>
              <Nav className="text-primary border-tab nav-primary">
                <NavLink
                  className={`tab-link ${activeTab === "1" ? "active" : ""}`}
                  onClick={() => toggleTab("1")}
                >
                  Unpaid Invoice
                </NavLink>
                <NavLink
                  className={`tab-link ${activeTab === "2" ? "active" : ""}`}
                  onClick={() => toggleTab("2")}
                >
                  Paid Invoice
                </NavLink>
              </Nav>

              <div className="added-titlerow d-flex justify-content-between align-items-center">
                <div>
                  {activeTab === "1" ? "Unpaid Invoice" : "Paid Invoice"}
                </div>
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <div className="support-history table-responsive">
                    <DataTable
                      columns={invoicepaidColumns}
                      data={invoiceData?.paid || []}
                      pagination
                      paginationServer
                      defaultSortAsc
                      sortServer
                      paginationRowsPerPageOptions={[10, 25, 50, 100]}
                      // paginationTotalRows={paidInvoice.length}
                      progressPending={loading}

                      paginationDefaultPage={currentPage}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="1">
                  <div className="support-history table-responsive">
                    <DataTable
                      columns={invoiceUnpaidColumns}
                      data={invoiceData?.unpaid || []}
                      pagination
                      paginationServer
                      defaultSortAsc
                      sortServer
                      progressPending={loading}

                      paginationRowsPerPageOptions={[10, 25, 50, 100]}
                      paginationDefaultPage={currentPage}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>

        <Popup isOpen={modalpay} toggler={handleModalpay}>
          <PayNow
            unPaidInvoice={invoiceData?.unpaid}
          />
        </Popup>
      </Card>
    </div>
  );
};

export default InvoiceTab;
