import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import NavSign from "./NavBar";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import { getData, postClientUrlWithoutToken } from "../Utils/restUtils";
import { guestPaymentConfirm, guestPaymentRequest, guestQuickpayPost } from "../CommonUrl";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NavbarsTab from "./NavBar";

const schema = yup.object().shape({
  subscriber_id: yup.string().required().label("Bin Number"),
  mobile: yup.string().required().label("Mobile Number"),
});

const QuickPayForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  const [isStep, setnextStep] = useState(1);
  const [apiResponse, setapiResponse] = useState(null);
  const [selectedSales, setSelectedSales] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (apiResponse?.total_dues !== undefined) {
      setAmount(apiResponse.total_dues);
    }
  }, [apiResponse]);

  useEffect(() => {
    setAmount(totalAmount.toFixed(2));
  }, [totalAmount]);

  const handleCheckboxChange = (id, amount) => {
    setSelectedItems((prevSelectedItems) => {
      const itemExists = prevSelectedItems.some((item) => item.id === id);

      if (itemExists) {
        setTotalAmount((prevTotalAmount) => prevTotalAmount - amount);
        return prevSelectedItems.filter((item) => item.id !== id);
      } else {
        setTotalAmount((prevTotalAmount) => prevTotalAmount + amount);
        return [...prevSelectedItems, { id, amount }];
      }
    });
  };

  const handleSalesOrderDues = (event) => {
    const checked = event.target.checked;
    const amount = parseFloat(apiResponse?.sales_due_total ?? 0);
    setSelectedSales(checked);
    if (checked) {
      setTotalAmount((prevTotal) => prevTotal + amount);
    } else {
      setTotalAmount((prevTotal) => prevTotal - amount);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleNextStep = () => {
    setnextStep(isStep + 1);
  };

  const handleMobileEmail = async (data) => {
    try {
      const response = await postClientUrlWithoutToken(guestQuickpayPost, {
        mobile: data?.mobile,
        subscriber_id: data?.subscriber_id,
      });
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          setapiResponse(response?.data);
          toast.success(response?.data?.message);
          handleNextStep();
        } else {
          toast.error(response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    let type = "any";
    const isBinChecked = selectedItems.length > 0;
    if (selectedSales && isBinChecked) {
      type = "bin_with_sales";
    } else if (selectedSales) {
      type = "sales_order";
    } else if (isBinChecked) {
      type = "subscription";
    }

    const postData = {
      request_from: "web",
      customer_id: apiResponse?.customer_id,
      amount: amount,
      bins:
        totalAmount === 0
          ? String(apiResponse?.data?.map((e) => e?.id))
          : String(selectedItems.map((item) => item.id).join(",")),
      gateway: "cybersource",
      type,
    };
    const paymentConfirm = await postClientUrlWithoutToken(guestPaymentRequest, postData);
    if (paymentConfirm?.status === 200) {
      if (paymentConfirm?.data?.status === true) {
        const response = await getData(
          guestPaymentConfirm + paymentConfirm?.data?.data?.order_request_id
        );
        document.open();
        document.write(response);
        document.close();
      } else {
        toast.error(paymentConfirm?.data?.message);
      }
    } else {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <NavbarsTab />
      <div className="signin-container d-flex justify-content-center align-item-center">
        <Card className="sign-in-card" style={{ marginTop: '30px' }}>
          <CardBody>
            {isStep === 1 && (
              <div className="signin-form-container">
                <h4 className="signin-heading" style={{ color: '#534686' }}>Quick Pay</h4>
                <p className="signin-subheading" style={{ color: '#534686' }}>
                  Please enter Bin number and Mobile number
                  <br /> registered with RKS
                </p>
                <form
                  className="signin-form"
                  onSubmit={handleSubmit(handleMobileEmail)}
                >
                  <label className="pt-4">Bin Number</label>
                  <input
                    {...register("subscriber_id")}
                    type="text"
                    placeholder="Enter Bin Number"
                  />
                  <p className="text-danger">{errors.subscriber_id?.message}</p>

                  <label className="mt-1">Mobile Number</label>
                  <input
                    {...register("mobile")}
                    type="text"
                    placeholder="Registered Mobile Number"
                  />
                  <p className="text-danger">{errors.mobile?.message}</p>

                  <div className="text-center mt-3 pb-3">
                    <button type="submit" className="forget-btn">
                      Get Bills
                    </button>
                  </div>
                </form>
              </div>
            )}

            {isStep === 2 && (
              <div className="pay-container p-4">
                <Card>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="tab-bar m-3 text-secondary">
                        <Link to="/login" className="text-decoration-none text-secondary">Home</Link>
                        &nbsp;&gt;&nbsp; Quick pay
                      </h5>
                    </div>
                  </div>
                </Card>
                <Row>
                  <div>
                    {apiResponse &&
                      apiResponse?.data?.map((e, index) => (
                        <Col md="12" sm="12" xs="12" className="mb-3 mt-5" key={index}>
                          <div className="paycard-box ">
                            <Card className="profile-field-card border">
                              <CardBody className="active-plan">
                                <div className=" d-flex justify-content-between ps-4 pe-4 pt-4 pb-4">
                                  <div className="d-flex pt-2 pb-2">
                                    <div className="subcription-item-bin ">
                                      <div className="table-column head">Bin Id</div>
                                      <div className="table-column">
                                        {e?.stb_no}
                                      </div>
                                    </div>
                                    <div className="subcription-item-dues ">
                                      <div className="table-column head">Dues</div>
                                      <div className="table-column due-color">
                                        B$ {e?.total_dues?.toFixed(2)}
                                      </div>
                                    </div>
                                    <div className="subcription-item-address address">
                                      <div className="table-column head">Bin Address</div>
                                      <div className="table-column">
                                        {e?.service_address}
                                      </div>
                                    </div>
                                  </div>
                                  <div className=" ">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.some(
                                        (item) => item.id === e.id
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(e?.id, e?.total_dues)
                                      }
                                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </Col>
                      ))}

                    <div className="pb-5 text-end sale-order-dues">
                      <div className="border rounded-5 p-3 align-items-center">
                        <span className="me-3">Sales Order Dues </span>
                        <span className="me-3 due-color">
                          B$ {(apiResponse?.sales_due_total ?? 0).toFixed(2)}
                        </span>
                        <input
                          type="checkbox"
                          onChange={handleSalesOrderDues}
                          style={{ width: "20px", height: "20px", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </Row>

                <div className="p-3 sale-order-dues d-flex justify-content-end align-items-center">
                  <span className="me-3">Total dues B$</span>
                  <div style={{ width: '20%', marginRight: '20px' }}>
                    <input
                      type="number"
                      className="form-control"
                      value={amount}
                      onChange={handleAmountChange}
                      style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    />
                  </div>
                  <button className="btn btn-success" onClick={() => onSubmit()}>
                    Pay Now
                  </button>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default QuickPayForm;
