import React, { useEffect, useState } from 'react'
import { FaSearch, FaFilter } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { guestfetchComplain } from '../../CommonUrl';
import ViewDetails from './ViewDetails';
import Popup from '../Common/Popup/popup';
import ViewComplainAdd from './ViewComplainAdd';
import { Card } from 'reactstrap';


const Request = () => {
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiResponse, setapiResponse] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const [isOpenAdd, setisOpenAdd] = useState(false);
  const [rowData, setrowData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchComplaintData();
  }, []);

  const SupportColumns = [
    {
      name: <h4>Sl.No</h4>,
      selector: (row, index) => index + 1,
      width: "90px",
    },
    {
      name: <h4>Date</h4>,
      selector: (row) => row.start_date,
      sortable: true,
      width: "140px",
      wrap: true,
    },
    {
      name: <h4>Request Id</h4>,
      selector: (row) => row.complaint_no,
      sortable: true,
      width: "120px",
      wrap: true,
    },
    {
      name: <h4>Request Type</h4>,
      selector: (row) => row.problem_type,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: <h4>Comments</h4>,
      selector: (row) => row.problem_detail,
      sortable: true,
      width: "160px",
      wrap: true,
    },
    {
      name: <h4>Bin Id</h4>,
      selector: (row) => row.stb_no,
      sortable: true,
      width: "160px",
      wrap: true,
    },

    {
      name: <h4>Status</h4>,
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span
          className="badge"
          style={{
            backgroundColor:
              row.status === "request"
                ? "#F8D7DA"
                : row.status === "inprocess"
                  ? "#FFF3CD"
                  : row.status === "processed"
                    ? "#D4EDDA"
                    : "",
            fontWeight: 400,
            textTransform: "capitalize",
            color: "black",
            padding: "6px 10px",
            fontSize: "12px",
          }}
        >
          {row.status}
        </span>
      ),
      width: "150px",
    },
    {
      name: <h4>Action</h4>,
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn ml-2 gradient-green"
            style={{
              padding: "1px 8px",
              backgroundColor: "#10b981",
              color: "#ffff",
            }}
            onClick={() => handleModalpay(row)}
          >
            View Details
          </button>
        </div>
      ),
      width: "195px",
    },
  ];

  const handleModalpay = (rowData) => {
    setrowData(rowData);
    setisOpen(!isOpen);
  };

  const handleModalAdd = () => {
    setisOpenAdd(!isOpenAdd);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const fetchComplaintData = async () => {
    setLoading(true);
    const response = await getClientUrlDataToken(guestfetchComplain);

    if (response?.status === true) {
      setapiResponse(response?.data?.data);
    } else {
      setLoading(false);
      setapiResponse([]);
    }
    setLoading(false);
  };

  return (
    <div className='container-main' style={{ marginTop: '140px' }}>
      <Card className="height-equals" style={{ margin: '50px 20px 50px 20px', top: '20px' }}>
        <div className="bg-white shadow-sm p-4 rounded-4">
          <div style={{ display: 'flex', justifyContent:'space-between' }}>
          <h3 className="heading-subtitle" style={{ fontWeight: 'bold', fontSize: '20px', color:'#472e85' }}>Support</h3>
            <button
              className="btn ml-2 gradient-blue"
              style={{ padding: "5px 18px", backgroundColor: "#10b981", color: "#ffff", fontWeight: '500' }}
              onClick={() => handleModalAdd()}
            >
              New Request +
            </button>
          </div>
          <div className="added-titlerow d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <div>Requests</div>
            {/* <div className="icons d-flex gap-3">
              <FaSearch style={{ fontSize: "18px", cursor: "pointer" }} title="Search" />
              <div className="vertical-line"></div>
              <FaFilter style={{ fontSize: "18px", cursor: "pointer" }} title="Filter" />
            </div> */}
          </div>
          <div className="mb-0"></div>
          <div className="support-history table-responsive">
            <DataTable
              columns={SupportColumns}
              data={apiResponse || []}
              pagination
              paginationServer
              defaultSortAsc
              sortServer
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={loading}
            />
          </div>

          <Popup isOpen={isOpen} toggler={handleModalpay}>
            <ViewDetails rowData={rowData} fetchComplaintData={fetchComplaintData} setisOpen={setisOpen} />
          </Popup>

          <Popup isOpen={isOpenAdd} toggler={handleModalAdd}>
            <ViewComplainAdd rowData={fetchComplaintData} setisOpenAdd={setisOpenAdd} />
          </Popup>
        </div>
      </Card>
    </div>
  );
}

export default Request