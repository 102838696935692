import React, { useEffect, useState } from 'react';
import { Col, Row, Card as BootstrapCard, CardBody } from 'reactstrap';
import Expired from './Expired';
import ExpiresOn from './ExpiresOn';
import Popup from '../Common/Popup/popup';
import { rksCustomerBin } from '../../CommonUrl';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import moment from 'moment';

const ActivePlan = () => {
    const [modalexpires, setmodalExpires] = useState(false);
    const [expiresValue, setExpiresValue] = useState({});
    const [expiresDate, setExpiresDate] = useState('');
    const [expiresPack, setExpiresPack] = useState('');
    const [modalexpired, setmodalExpired] = useState(false);
    const [apiResponse, setapiResponse] = useState(null);

    const handleModalexpires = (e, formatedDate, name) => {
        setExpiresValue(e);
        setExpiresPack(name);
        setExpiresDate(formatedDate)
        setmodalExpires(!modalexpires);
    }

    const handleModalexpired = (e, formatedDate, name) => {
        setExpiresDate(formatedDate);
        setExpiresPack(name);
        setExpiresValue(e);
        setmodalExpired(!modalexpired);
    }

    useEffect(() => {
        const fetchDashboardData = async () => {
            const response = await getClientUrlDataToken(rksCustomerBin);
            setapiResponse(response?.data);
        }
        fetchDashboardData();
    }, [])

    return (
        <div className='container-main' style={{ marginTop: '140px' }}>
            <BootstrapCard className="height-equals" style={{ margin: '50px 20px 50px 20px', top: '20px' }}>
                <div className='activeplan-container' style={{ padding: '30px' }}>
                    <Row>
                        <h3 className="heading-subtitle" style={{ fontWeight: 'bold', fontSize: '20px', color: '#472e85' }}>Active plan</h3>
                        {apiResponse?.map((e, index) => (
                            <Col md="6" sm="12" xs="12" className="mb-3 mt-3" key={index}>
                                <BootstrapCard className="profile-field-card">
                                    <CardBody className='active-plan'>
                                        <div className="table-containers">
                                            {e?.all_subscriptions?.map((subscription, subIndex) => {
                                                const expiryDate = new Date(subscription?.end_date);
                                                const isExpired = moment(expiryDate).isBefore(moment());
                                                const formattedDate = moment(expiryDate).format("DD MMM YY");
                                                let ParsedString = {};
                                                try {
                                                    ParsedString = JSON.parse(subscription?.package_details || '{}');
                                                } catch (error) {
                                                    console.error("Error parsing package_details:", error);
                                                }

                                                return (
                                                    <div className="expiry-and-table" key={subIndex}>
                                                        <div className={isExpired ? "expired-date" : "expiry-date"}>
                                                            {isExpired ? `Expired on ${formattedDate}` : `Expires in ${formattedDate}`}
                                                        </div>
                                                        <div className="table-container">
                                                            <div className="table-row table-header">
                                                                <div>Bin Id</div>
                                                                <div>Subscriber Id</div>
                                                                <div>Plan</div>
                                                                <div>Actions</div>
                                                            </div>

                                                            <div className="table-row">
                                                                <div>{subscription?.stb_no}</div>
                                                                <div>{e?.subscriber_id}</div>
                                                                <div>{ParsedString?.name} {ParsedString?.description && ParsedString.description}</div>
                                                                <div>
                                                                    <div className="view-click" onClick={() => (isExpired ? handleModalexpired(e, formattedDate, ParsedString?.id) : handleModalexpires(e, formattedDate, ParsedString?.name))}>View</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </CardBody>
                                </BootstrapCard>
                            </Col>
                        ))}
                    </Row>

                    <Popup isOpen={modalexpires} toggler={handleModalexpires}>
                        <ExpiresOn
                            expiresValue={expiresValue}
                            expireDate={expiresDate}
                            pack={expiresPack}
                        />
                    </Popup>

                    <Popup isOpen={modalexpired} toggler={handleModalexpired}>
                        <Expired
                            expiresValue={expiresValue}
                            expireDate={expiresDate}
                            pack={expiresPack}
                        />
                    </Popup>
                </div>
            </BootstrapCard>
        </div>
    );
};

export default ActivePlan;
