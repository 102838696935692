import CryptoJS from 'crypto-js';

const secretKey = 'your-secret-key'; 

export function setUser(token, userType, master_token, master_subscription) {
  const userData = {
    userToken: token,
    userType: userType,
    masterToken: master_token,
    masterSubscription: master_subscription
  };

  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(userData),
    secretKey
  ).toString();

  localStorage.setItem('login', encryptedData);
}

export function getUser() {
  const encryptedData = localStorage.getItem('login');
  if (!encryptedData) {
    return null;
  }

  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
}
  export function signOutUser() {
    localStorage.clear();
    // console.log('sign out');
  }
  export function isLoggedIn() {
    const user = getUser();

    if (user !== null) {
      return true;
    } else {
      return false;
    }
  }